import {nyalaNormalBase64} from "../../Pages/Admin/Fonts/nyala-normal";
import {lastNinjaBase64} from "../../Pages/Admin/Fonts/lastninja";
import {nyalaBoldBase64} from "../../Pages/Admin/Fonts/nyala-bold";
import {parchmentNormalBase64} from "../../Pages/Admin/Fonts/Parchment MF-normal";
import {logo} from "../../Pages/Admin/Images/Logo";
import JsPDF from 'jspdf';

export async function printTestingSheets() {
  const doc = new JsPDF({
    orientation: "portrait",
    format: 'letter',
  });
  let empty = true
  let count = 0

  await this.getStepSparring()

  function addFontToPDF(pdf) {
    pdf.addFileToVFS('nyala.ttf', nyalaNormalBase64);
    pdf.addFont('nyala.ttf', 'nyala', 'normal');
    pdf.addFileToVFS('lastninja.ttf', lastNinjaBase64);
    pdf.addFont('lastninja.ttf', 'lastninja', 'normal');
    pdf.addFileToVFS('nyala-bold.ttf', nyalaBoldBase64);
    pdf.addFont('nyala-bold.ttf', 'nyala', 'bold');
    pdf.addFileToVFS('nyala-italics.ttf', nyalaBoldBase64);
    pdf.addFont('nyala-italics.ttf', 'nyala', 'italic');
    pdf.addFileToVFS('Parchment.ttf', parchmentNormalBase64);
    pdf.addFont('Parchment.ttf', 'Parchment', 'normal');
  }

  addFontToPDF(doc)

  for (let order of this.givenData){
    let index = this.givenData.indexOf(order) + 1
    this.precentage = (index / this.givenData.length) * 100
    this.precentage = parseFloat((Math.round(this.precentage * 100) / 100).toFixed(1))

    if (this.checkSelected(order.id) || this.givenData.length === 1) {
      empty = false
      count++
      doc.setFontSize('10');
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const halfPage = pageWidth / 2;
      let x = 10;
      let y = 10;

      if (order.program.name.includes('Foundations')){
        //console.log(order.rank)
        function printFoundationsTopInfo(rank){
          doc.setFontSize('14')
          y = 10
          x = 10
          doc.setFont('times', 'bold')
          let textWidth = doc.getTextWidth('Student Name:')
          doc.text("Student Name:", x, y);
          doc.setFont('nyala', 'normal')
          doc.text(order.user.first_name + " " + order.user.last_name, x + (textWidth + 1), y);
          x = pageWidth - 10
          textWidth = doc.getTextWidth(rank.name)
          doc.setFont('times', 'bold')
          doc.text("Current Rank:", x - (textWidth + 1), y, 'right')
          doc.setFont('nyala', 'normal')
          doc.text(rank.name, x, y, 'right')
          y += 1
          x = 10
          doc.setFont('times', 'bold')
          textWidth = doc.getTextWidth('Student Name:')
          doc.line(x + textWidth, y, halfPage, y);
          doc.setFont('nyala', 'normal')
          textWidth = doc.getTextWidth(rank.name)
          doc.line(pageWidth - textWidth - 10, y, pageWidth - 10, y);
          y += 10;
        }

        printFoundationsTopInfo(order.rank)
        y += 10
        doc.setFont('times', 'bold')
        doc.setFontSize('20');
        let titleString = order.program.name + ' Testing - '
        if (order.rank.unit_id){
          titleString += order.rank.unit.name
        } else {
          titleString += order.rank.name
        }
        doc.text(titleString, x, y);

        doc.addImage(logo, "PNG", halfPage + (halfPage / 2), 15, 50, 45);

        y += 15

        for (let category of this.categories) {
          switch (category.id) {
            case "poomsae":
              await this.getPoomsaeByRankId(order.rank.id)
              break
            case "kicks":
              await this.getKicksByRankId(order.rank.id)
              break
            case "self_defense":
              await this.getSelfDefByRankId(order.rank.id)
              break
            case "kick_combos":
              await this.getKickCombosByRankId(order.rank.id)
              break
            case "techniques":
              await this.getTechsByRankId(order.rank.id)
              break
            case 'spirit':
              continue
            case "breaking":
              await this.getDefaultOutlineBreaksByRankId(order.rank.id)
              break
          }
          if (category.items.length > 0) {
            for (let item of category.items) {
              doc.setFontSize('15')
              if (y > (pageHeight - 21)){
                doc.addPage()
                printTopInfo(order.rank)
              }
              if (category.items.indexOf(item) === 0){
                x = 10
                y += 5
                doc.setFont('Times-Roman', 'bolditalic')
                doc.text(category.name + ' (' + category.tape + ' Tape):', x, y)
              }
              doc.setFontSize('15')
              x = 30
              doc.setFont('nyala', 'normal')
              let originalY = y
              let printName = ''
              switch (category.id){
                case 'kicks':
                case 'kick_combos':
                  printName = item.description
                  break
                case 'breaking':
                  printName = item.techName
                  break
                default:
                  printName = item.name
                  break
              }
              if (doc.getTextWidth(printName) >= 80){
                let splitStr = printName.split(' ')
                let builtStr = ''
                let textWidth
                while (splitStr.length !== 0){
                  textWidth = doc.getTextWidth(builtStr + splitStr[0] + ' ')
                  if (textWidth > 80){
                    doc.text(builtStr, x + 50, y)
                    y += 4
                    builtStr = splitStr.shift() + ' '
                  } else {
                    builtStr += splitStr.shift() + ' '
                  }
                }
                doc.text(builtStr, x + 50, y)
              } else {
                doc.text(printName, x + 50, y)
              }

              y += 6
            }
          }
        }

        let rectSize = 130

        if (y > (pageHeight - (rectSize / 8) + 10)){
          doc.addPage()
          printTopInfo(order.rank)
        }
        let paddedRect = halfPage - (rectSize / 2) + 3
        x = halfPage - (rectSize / 2)
        y = pageHeight - 10 - (rectSize / 8)
        doc.setFillColor(230, 230, 230);
        doc.rect(x, y, rectSize, rectSize / 8, 'DF')
        doc.setFont('Times-Roman', 'bold')
        doc.setFontSize('10')
        y += 6
        x = paddedRect
        doc.text('Date:', x, y)
        x = halfPage
        doc.text('Grader:', x, y)
        y += 1
        let textWidth = doc.getTextWidth('Date:')
        doc.line(paddedRect + textWidth, y, halfPage - 3, y);
        textWidth = doc.getTextWidth('Grader:')
        doc.line(halfPage + textWidth, y, halfPage + (rectSize / 2) - 3, y);
        y += 5
        x = paddedRect
        doc.text('Next Test Date:', x, y)
        x = halfPage
        doc.text('Advanced to:', x, y)
        textWidth = doc.getTextWidth('Advanced to:')
        doc.setFont('nyala', 'normal')
        doc.text(order.nextRank.name, x + textWidth + 1, y)
        y += 1
        doc.setFont('Times-Roman', 'bold')
        textWidth = doc.getTextWidth('Next Test Date:')
        doc.line(paddedRect + textWidth, y, halfPage - 3, y);
        textWidth = doc.getTextWidth('Advanced to:')
        doc.line(halfPage + textWidth, y, halfPage + (rectSize / 2) - 3, y);

        rectSize = 200
        paddedRect = halfPage - (rectSize / 2) + 3
        x = halfPage - (rectSize / 2)
        y = pageHeight - 10 - (rectSize / 8) - (130 / 8) - 3
        doc.setFillColor(230, 230, 230);
        doc.rect(x, y, rectSize, rectSize / 8, 'DF')
        doc.setFont('Times-Roman', 'bold')
        doc.setFontSize('10')
        y += 6
        x = paddedRect
        doc.text('Comments:', x, y)
        if (order.comments) {
          y += 4
          doc.setFont('Times-Roman', 'normal')
          if (doc.getTextWidth(order.comments) > 195){
            let splitStr = order.comments.split(' ')
            let builtStr = ''
            let textWidth
            while (splitStr.length !== 0){
              textWidth = doc.getTextWidth(builtStr + splitStr[0] + ' ')
              if (textWidth > 195){
                doc.text(builtStr, x, y)
                y += 4
                builtStr = splitStr.shift() + ' '
              } else {
                builtStr += splitStr.shift() + ' '
              }
            }
            doc.text(builtStr, x, y)
          } else {
            doc.text(order.comments, x, y)
          }
        }
      } else {
        function printTopInfo(rank){
          doc.setFontSize('10')
          y = 10
          x = 10
          doc.setFont('times', 'bold')
          let textWidth = doc.getTextWidth('Student Name:')
          doc.text("Student Name:", x, y);
          doc.setFont('nyala', 'normal')
          doc.text(order.user.first_name + " " + order.user.last_name, x + (textWidth + 1), y);
          x = pageWidth - 10
          textWidth = doc.getTextWidth(rank.name)
          doc.setFont('times', 'bold')
          doc.text("Current Rank:", x - (textWidth + 1), y, 'right')
          doc.setFont('nyala', 'normal')
          doc.text(rank.name, x, y, 'right')
          y += 1
          x = 10
          doc.setFont('times', 'bold')
          textWidth = doc.getTextWidth('Student Name:')
          doc.line(x + textWidth, y, halfPage, y);
          doc.setFont('nyala', 'normal')
          textWidth = doc.getTextWidth(rank.name)
          doc.line(pageWidth - textWidth - 10, y, pageWidth - 10, y);
          y += 10;
        }

        printTopInfo(order.rank)
        doc.setFont('times', 'bold')
        doc.setFontSize('16');
        let titleString = 'Beyond Sports Taekwondo Testing Card'
        x = halfPage
        doc.text(titleString, x, y, 'center');
        y += 5
        let rectSize = 175
        x = halfPage - (rectSize / 2)
        doc.setFillColor(230, 230, 230);
        doc.rect(x, y, rectSize, rectSize / 8, 'DF')
        x = pageWidth / 3
        let boxy = y + 6
        doc.setFontSize('10')
        doc.setFont('nyala', 'normal')
        doc.text('Not Ready: Execution is below capability', x, boxy, 'center')
        x += x
        doc.text('Good: Execution above expectation', x, boxy, 'center')
        boxy += 6
        x = pageWidth / 3
        doc.text('Getting There: Execution is acceptable for capability', x, boxy, 'center')
        x += x
        doc.text('Excellent: Blown away by Execution', x, boxy, 'center')
        boxy += 6
        x = halfPage
        doc.setFontSize('8')
        doc.text('Note: Excellent does not mean perfect-There is always room to grow!', x, boxy, 'center')
        y += 9 + (rectSize / 8)
        x = 10

        for (let category of this.categories) {
          switch (category.id) {
            case "poomsae":
              await this.getPoomsaeByRankId(order.rank.id)
              break
            case "kicks":
              await this.getKicksByRankId(order.rank.id)
              break
            case "self_defense":
              await this.getSelfDefByRankId(order.rank.id)
              break
            case "kick_combos":
              await this.getKickCombosByRankId(order.rank.id)
              break
            case "techniques":
              await this.getTechsByRankId(order.rank.id)
              break
            case "breaking":
              await this.getDefaultOutlineBreaksByRankId(order.rank.id)
              break
          }
          if (category.items.length > 0) {
            for (let item of category.items) {
              if (category.items.indexOf(item) === 0){
                if (y > (pageHeight - (23 * category.items.length) + 7)){
                  doc.addPage()
                  printTopInfo(order.rank)
                }
                x = 10
                doc.setFont('Times-Roman', 'bolditalic')
                doc.setFontSize('14')
                doc.text(category.name, x, y)
              }
              x = 15
              y += 7
              doc.setFontSize('12')
              rectSize = 6
              doc.rect(x, y - 4, rectSize + 2, rectSize)
              doc.setFont('nyala', 'normal')
              let originalY = y
              let printName = ''
              switch (category.id){
                case 'kicks':
                case 'kick_combos':
                  printName = item.description
                  break
                case 'breaking':
                  printName = item.techName
                  break
                default:
                  printName = item.name
                  break
              }
              if (doc.getTextWidth(printName) >= 80){
                let splitStr = printName.split(' ')
                let builtStr = ''
                let textWidth
                while (splitStr.length !== 0){
                  textWidth = doc.getTextWidth(builtStr + splitStr[0] + ' ')
                  if (textWidth > 80){
                    doc.text(builtStr, x + 15, y)
                    y += 4
                    builtStr = splitStr.shift() + ' '
                  } else {
                    builtStr += splitStr.shift() + ' '
                  }
                }
                doc.text(builtStr, x + 15, y)
              } else {
                doc.text(printName, x + 15, y)
              }

              doc.setFontSize('11')
              doc.setFont('Times-Roman', 'bolditalic')
              doc.text("Not Ready", x + 95, originalY)
              doc.text("Getting There", x + 120, originalY)
              doc.text("Good", x + 150, originalY)
              doc.text("Excellent", x + 170, originalY)
              x = 15 + 15
              y += 10
              doc.line(x, y, pageWidth - 15, y);
              y += 6
            }
          }
        }
        this.clearCategories()
        y += 5
        rectSize = 130

        if (y > (pageHeight - (rectSize / 8) + 10)){
          doc.addPage()
          printTopInfo(order.rank)
        }
        let paddedRect = halfPage - (rectSize / 2) + 3
        x = halfPage - (rectSize / 2)
        doc.setFillColor(230, 230, 230);
        doc.rect(x, y, rectSize, rectSize / 8, 'DF')
        doc.setFont('Times-Roman', 'bold')
        doc.setFontSize('10')
        y += 6
        x = paddedRect
        doc.text('Date:', x, y)
        x = halfPage
        doc.text('Grader:', x, y)
        y += 1
        let textWidth = doc.getTextWidth('Date:')
        doc.line(paddedRect + textWidth, y, halfPage - 3, y);
        textWidth = doc.getTextWidth('Grader:')
        doc.line(halfPage + textWidth, y, halfPage + (rectSize / 2) - 3, y);
        y += 5
        x = paddedRect
        doc.text('Next Test Date:', x, y)
        x = halfPage
        doc.text('Advanced to:', x, y)
        textWidth = doc.getTextWidth('Advanced to:')
        doc.setFont('nyala', 'normal')
        doc.text(order.nextRank.name, x + textWidth + 1, y)
        y += 1
        doc.setFont('Times-Roman', 'bold')
        textWidth = doc.getTextWidth('Next Test Date:')
        doc.line(paddedRect + textWidth, y, halfPage - 3, y);
        textWidth = doc.getTextWidth('Advanced to:')
        doc.line(halfPage + textWidth, y, halfPage + (rectSize / 2) - 3, y);
      }

      if (count < this.selectedData.length){
        doc.addPage();
      }
    }
  }

  if (!empty) {
    let fileName = this.printName + " Testing Sheets.pdf"
    doc.save(fileName);
  } else {
    this.error_txt = "No Competitors selected"
    this.openModal('error')
  }
}
