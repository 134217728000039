export async function setTechniqueNames(outline) {
  if (outline && outline.breaking_techniques_to_outlines) {
    for (const techItem of outline.breaking_techniques_to_outlines) {
      techItem.techName = '';
      for (const modifier of this.dropdown_support_options) {
        if (modifier.id === techItem.dropdown_modifier_id) {
          techItem.techName += modifier.description + ' ';
        }
      }
      if (techItem.breaking_technique && techItem.breaking_technique.name) {
        techItem.techName += techItem.breaking_technique.name + ', ';
      }
      if (techItem.breaking_technique && techItem.breaking_technique.directions) {
        for (const techdir of techItem.breaking_technique.directions) {
          if (techdir && techdir.id !== null && techdir.id === techItem.direction_id) {
            if (techdir.name !== 'Regular') {
              techItem.techName += techdir.name + ', ';
            }
          }
        }
      }
      if (techItem.blindfolded) {
        techItem.techName += 'Blindfolded, ';
      }
      if (techItem.self_held) {
        techItem.techName += 'Self Held, ';
      }
      if (techItem.simultaneous) {
        techItem.techName += 'Simultaneous, ';
      }
      if (techItem.successive) {
        techItem.techName += 'Successive, ';
      }
      if (techItem.no_spacers) {
        techItem.techName += 'No Spacers, ';
      }
      techItem.techName = techItem.techName.replace(/,\s*$/, "");
      if (techItem.additional_boards === 1) {
        techItem.techName += ' 1 Additional Board';
      } else if (techItem.additional_boards > 1) {
        techItem.techName += techItem.additional_boards + ' Additional Boards';
      }
    }
  }
}
