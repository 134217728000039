<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <div slot="header">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12">
                <h4 class="card-title">Rank Checklist</h4>
                <p class="card-category">View the details of the next rank checklist</p>
                <p class="card-category">
                  <el-button type="primary" @click="generatePDF">Print Checklist</el-button>
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row align-items-center">
              <div class="col-sm-12 float-right">
                <p class="float-right" v-if="userRank">
                  <span style="font-weight: bold" v-if="userRank">Current Rank:</span> {{userRank.name}}
                  <br>
                  <span style="font-weight: bold" v-if="nextRank">Next Rank:</span> {{nextRank.name}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-for="category in categories" v-bind:key="category.id">
        <div v-if="category.items.length > 0">
          <el-table stripe :data="category.items" style="width: 100%; border-bottom: solid 2px black">
            <el-table-column >
              <template slot="header">
                <h5 class="customTitle">{{category.name}}</h5>
              </template>
              <template v-slot="scope">
                {{scope.row.name}}
              </template>
            </el-table-column>
          </el-table>
          <br>
        </div>
      </div>
    </card>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>Success: {{ success_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="closeModal('success')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="closeModal('error')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option, Button, Dialog, Checkbox } from 'element-ui';
import moment from 'moment';
import jsPDF from 'jspdf';
import RanksAPIService from "src/servicehandlers/RanksAPIService";
import BreakingTechniquesAPIService from "src/servicehandlers/BreakingTechniquesAPIService";
import PoomsaeAPIService from "src/servicehandlers/PoomsaeAPIService";
import KicksAPIService from "src/servicehandlers/KicksAPIService";
import SelfDefenseTechniquesAPIService from "src/servicehandlers/SelfDefenseTechniquesAPIService";
import KickCombosAPIService from "src/servicehandlers/KickCombosAPIService";
import TechniquesAPIService from "src/servicehandlers/TechniquesAPIService";
import TerminologyAPIService from "src/servicehandlers/TerminologyAPIService";
import DocumentationAPIService from "src/servicehandlers/DocumentationAPIService";
import ProgramsAPIService from "src/servicehandlers/ProgramsAPIService";
import MotionsAPIService from "src/servicehandlers/MotionsAPIService";
import RankTypesAPIService from "../../../../servicehandlers/RankTypesAPIService";
import UserAPIService from "../../../../servicehandlers/UsersAPIService";
import {nyalaNormalBase64} from "../Admin/Fonts/nyala-normal";
import {nyalaBoldBase64} from "../Admin/Fonts/nyala-bold";

const ranksAPIService = new RanksAPIService();
const breakingTechniquesAPIService = new BreakingTechniquesAPIService();
const poomsaeAPIService = new PoomsaeAPIService();
const kicksAPIService = new KicksAPIService();
const selfDefenseTechniquesAPIService = new SelfDefenseTechniquesAPIService();
const kickCombosAPIService = new KickCombosAPIService();
const techniqueAPIService = new TechniquesAPIService();
const terminologyAPIService = new TerminologyAPIService();
const documentationAPIService = new DocumentationAPIService();
const programsAPIService = new ProgramsAPIService();
const motionsAPIService = new MotionsAPIService();
const rankTypesAPIService = new RankTypesAPIService();
const userAPIService = new UserAPIService();

export default {
  components: {
    [Dialog.name]: Dialog,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
  },
  name: "rank-checklist",
  data() {
    return {
      modals: {
        error: false,
        success: false,
      },
      spinning: false,
      ranks: [],
      steps: [],
      rank_types: [],
      programs: [],
      filter_ranks: [],
      limited_ranks: [],
      selectable: true,
      table: [1],
      selectAll: false,
      limitors: {
        beginner: {
          id: 0,
          value: false,
        },
        intermediate: {
          id: 0,
          value: false,
        },
        advanced: {
          id: 0,
          value: false,
        },
      },
      selected_program: null,
      selected_rank: null,
      selected_ranks: [],
      show_rank_reorder: false,
      rank_button_text: 'Reorder Ranks',
      selectedItems: {
        'breaking_techniques': null,
        'poomsae': null,
        'kicks': null,
        'self_defense': null,
        'kick_combos': null,
        'techniques': null,
        'terminology': null,
        'documentation': null,
        'programs': null,
      },
      selected_dropdown_item: null,
      error_txt: null,
      success_txt: null,
      categories: [
        {
          id: 'breaking_techniques',
          name: 'Breaking(Green Tape)',
          items: [""],
          dropdown_list: [""],
          visible: true
        },
        {
          id: 'poomsae',
          name: 'Poomsae(Black Tape)',
          items: [""],
          dropdown_list: [""],
          visible: true
        },
        {
          id: 'kicks',
          name: 'Kicks(Blue Tape)',
          items: [""],
          dropdown_list: [""],
          visible: true
        },
        {
          id: 'self_defense',
          name: 'Self Defence(Brown Tape)',
          items: [""],
          dropdown_list: [""],
          visible: true
        },
        {
          id: 'kick_combos',
          name: 'Kick Combos(Red Tape)',
          items: [""],
          dropdown_list: [""],
          visible: true
        },
        {
          id: 'techniques',
          name: 'Techniques',
          items: [""],
          dropdown_list: [""],
          visible: true
        },
        {
          id: 'terminology',
          name: 'Yellow Tape(ADD PDF FOR MEDIA)',
          items: [""],
          dropdown_list: [""],
          visible: true
        },
        {
          id: 'documentation',
          name: 'White (ADD PDF FOR MEDIA)',
          items: [""],
          dropdown_list: [""],
          visible: true
        },
      ],
      userId: null,
      userName: null,
      userRankId: null,
      userRank: null,
      nextRank: null,
      nextRankBool: false,
    }
  },
  mounted() {
    this.getRanks()
    this.userId = this.$store.getters.userId
    this.getUserRankId()
  },
  methods: {
    getItemById(array, id) {
      return array.find(item => item.id === id);
    },
    closeModal(name) {
      this.modals[name] = false
    },
    async generatePDF() {
      this.spinning = true;
      // get kicks and motions for the kick combos

      const date = moment().format('MM-DD-YYYY');
      // Get the width of the page
      const doc = new jsPDF({
        orientation: "portrait",
        format: 'letter',
      });
      let empty = true
      let count = 0

      function addFontToPDF(pdf) {
        pdf.addFileToVFS('nyala.ttf', nyalaNormalBase64);
        pdf.addFont('nyala.ttf', 'nyala', 'normal');
        pdf.addFileToVFS('nyala-bold.ttf', nyalaBoldBase64);
        pdf.addFont('nyala-bold.ttf', 'nyala', 'bold');
        pdf.addFileToVFS('nyala-italics.ttf', nyalaBoldBase64);
        pdf.addFont('nyala-italics.ttf', 'nyala', 'italic');
      }

      addFontToPDF(doc)

      doc.setFontSize('10');
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const halfPage = pageWidth / 2;
      // do this in two page segments
      let x = 10;
      let y = 10;
      let VO = this

      function printTopInfo(rank){
        doc.setFontSize('10')
        y = 10
        x = 10
        doc.setFont('times', 'bold')
        let textWidth = doc.getTextWidth('Student Name:')
        doc.text("Student Name:", x, y);
        doc.setFont('nyala', 'normal')
        doc.text(VO.userName, x + (textWidth + 1), y);
        x = pageWidth - 10
        textWidth = doc.getTextWidth(rank.name)
        doc.setFont('times', 'bold')
        doc.text("Current Rank:", x - (textWidth + 1), y, 'right')
        doc.setFont('nyala', 'normal')
        doc.text(rank.name, x, y, 'right')
        y += 1
        x = 10
        doc.setFont('times', 'bold')
        textWidth = doc.getTextWidth('Student Name:')
        doc.line(x + textWidth, y, halfPage, y);
        doc.setFont('nyala', 'normal')
        textWidth = doc.getTextWidth(rank.name)
        doc.line(pageWidth - textWidth - 10, y, pageWidth - 10, y);
        y += 10;
      }

      printTopInfo(this.userRank)
      doc.setFont('times', 'bold')
      doc.setFontSize('16');
      let titleString = 'Beyond Sports Taekwondo Testing Card'
      x = halfPage
      doc.text(titleString, x, y, 'center');
      y += 5
      let rectSize = 175
      x = halfPage - (rectSize / 2)
      doc.setFillColor(230, 230, 230);
      doc.rect(x, y, rectSize, rectSize / 8, 'DF')
      x = pageWidth / 3
      let boxy = y + 6
      doc.setFontSize('10')
      doc.setFont('nyala', 'normal')
      doc.text('Not Ready: Execution is below capability', x, boxy, 'center')
      x += x
      doc.text('Good: Execution above expectation', x, boxy, 'center')
      boxy += 6
      x = pageWidth / 3
      doc.text('Getting There: Execution is acceptable for capability', x, boxy, 'center')
      x += x
      doc.text('Excellent: Blown away by Execution', x, boxy, 'center')
      boxy += 6
      x = halfPage
      doc.setFontSize('8')
      doc.text('Note: Excellent does not mean perfect-There is always room to grow!', x, boxy, 'center')
      y += 9 + (rectSize / 8)
      x = 10

      for (let category of this.categories) {
        if (y > (pageHeight - 21)){
          doc.addPage()
          printTopInfo(this.userRank)
        }
        doc.setFont('Times-Roman', 'bold')
        doc.setFontSize('14')
        doc.text(category.name, x, y)
        if (category.items.length > 0) {
          for (let item of category.items) {
            if (y > (pageHeight - 16)){
              doc.addPage()
              printTopInfo(this.userRank)
            }
            x = 15
            y += 7
            doc.setFontSize('12')
            rectSize = 5
            doc.rect(x, y - 4, rectSize, rectSize)
            doc.setFont('nyala', 'normal')
            doc.text(item.name, x + 15, y)
            doc.setFont('Times-Roman', 'italic')
            doc.text("Not Ready", x + 95, y)
            doc.text("Getting There", x + 120, y)
            doc.text("Good", x + 150, y)
            doc.text("Excellent", x + 170, y)
          }

        }
        x = 10
        y += 4
        doc.line(x, y, pageWidth - 10, y);
        y += 12
      }

      rectSize = 130

      if (y > (pageHeight - (rectSize / 8) + 10)){
        doc.addPage()
        printTopInfo(this.userRank)
      }
      let paddedRect = halfPage - (rectSize / 2) + 3
      x = halfPage - (rectSize / 2)
      doc.setFillColor(230, 230, 230);
      doc.rect(x, y, rectSize, rectSize / 8, 'DF')
      doc.setFont('Times-Roman', 'bold')
      doc.setFontSize('10')
      y += 6
      x = paddedRect
      doc.text('Date:', x, y)
      x = halfPage
      doc.text('Grader:', x, y)
      y += 1
      let textWidth = doc.getTextWidth('Date:')
      doc.line(paddedRect + textWidth, y, halfPage - 3, y);
      textWidth = doc.getTextWidth('Grader:')
      doc.line(halfPage + textWidth, y, halfPage + (rectSize / 2) - 3, y);
      y += 5
      x = paddedRect
      doc.text('Next Test Date:', x, y)
      x = halfPage
      doc.text('Advanced to:', x, y)
      textWidth = doc.getTextWidth('Advanced to:')
      doc.setFont('nyala', 'normal')
      doc.text(this.nextRank.name, x + textWidth + 1, y)
      y += 1
      doc.setFont('Times-Roman', 'bold')
      textWidth = doc.getTextWidth('Next Test Date:')
      doc.line(paddedRect + textWidth, y, halfPage - 3, y);
      textWidth = doc.getTextWidth('Advanced to:')
      doc.line(halfPage + textWidth, y, halfPage + (rectSize / 2) - 3, y);

      let fileName = this.nextRank.name + " Testing Sheet.pdf"
      doc.save(fileName);
      this.spinning = false;

      /*const doc = new jsPDF({
        orientation: "landscape",
        format: 'letter',
      });
      doc.setFontSize('12');
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const halfPage = pageWidth / 2;
      // do this in two page segments
      let x = 10;
      let y = 5;
      doc.text(date, x, y);
      y += 7;
      doc.setFontSize('12');
      doc.text('Beyond Sports Taekwondo Curriculum', x, y);
      y += 7;

      doc.setFontSize('12');
      doc.text(this.nextRank.name, x, y);
      y += 7;
      doc.text(this.userName, x, y);
      y += 9;

      for (let category of this.categories){
        if (category.items.length > 0){
          doc.text(category.name, x, y)
          y += 3;
          doc.line(x, y, pageWidth, y);
          y += 5;
          for (let item of category.items){
            let size = 5
            doc.rect(x, y - 4, size, size)
            doc.text(item.name, x + 15, y)
            doc.text("Not Ready", x + 146, y)
            doc.text("Getting There", x + 175, y)
            doc.text("Good", x + 210, y)
            doc.text("Excellent", x + 230, y)
            y += 5;
          }
        }
      }

      x = 10;
      y = 10;


      let fileName = this.nextRank.name + " Checklist.pdf"
      doc.save(fileName);*/
    },
    getTechniques() {
      return techniqueAPIService.getTechnique(this.$router)
      .then((response) => {
        for (const category of this.categories) {
          if (category.id === 'techniques') {
            category.dropdown_list = [];
            for (const item of response) {
              item.value = item.id;
              item.text = item.description;
              item.name = item.description;
              category.dropdown_list.push(item);
            }
            break;
          }
        }
        return techniqueAPIService.getTechniqueByRank(this.userRank.id, this.$router)
      })
      .then((response) => {
        // now sort through the items
        for (const category of this.categories) {
          if (category.id === 'techniques') {
            category.items = [];
            for (const item of response) {
              const db_items = item.techniques;
              for (const iteritem of db_items) {
                iteritem.tested = false;
                if (iteritem.techniques_to_ranks) {
                  iteritem.tested = iteritem.techniques_to_ranks.tested;
                }
                iteritem.value = iteritem.id;
                iteritem.text = iteritem.description;
                iteritem.name = iteritem.description;
                if (iteritem.tested){
                  category.items.push(iteritem);
                }
              }
            }
            break;
          }
        }
      })
      .catch((error) => {
        this.error_txt = 'Error getting techniques: ' + error;
        this.openModal('error');
      });
    },
    getDocumentation() {
      return documentationAPIService.getDocumentation(this.$router)
      .then((response) => {
        for (const category of this.categories) {
          if (category.id === 'documentation') {
            category.dropdown_list = [];
            for (const item of response) {
              item.value = item.id;
              item.text = item.description;
              item.name = item.description;
              category.dropdown_list.push(item);
            }
            break;
          }
        }
        return documentationAPIService.getDocumentationByProgram(this.userRank.id, this.$router)
      })
      .then((response) => {
        // now sort through the items
        for (const category of this.categories) {
          if (category.id === 'documentation') {
            category.items = [];
            for (const item of response) {
              const db_items = item.documentations;
              for (const iteritem of db_items) {
                iteritem.tested = false;
                if (iteritem.documentation_to_programs) {
                  iteritem.tested = iteritem.documentation_to_programs.tested;
                }
                iteritem.value = iteritem.id;
                iteritem.text = iteritem.description;
                iteritem.name = iteritem.description;
                if (iteritem.tested){
                  category.items.push(iteritem);
                }
              }
            }
            break;
          }
        }
      })
      .catch((error) => {
        this.error_txt = 'Error getting documentation: ' + error;
        this.openModal('error');
      });
    },
    getKickCombos() {
      return kickCombosAPIService.getKickCombos(this.$router)
      .then((response) => {
        response.sort((a, b) => {
          if (a.order && b.order) {
            return a.order - b.order;
          }
          return 0;
        });
        for (const category of this.categories) {
          if (category.id === 'kick_combos') {
            category.dropdown_list = [];
            for (const item of response) {
              item.value = item.id;
              item.text = item.description;
              item.name = item.description;
              category.dropdown_list.push(item);
            }
            break;
          }
        }
        return kickCombosAPIService.getKickCombosByRank(this.userRank.id, this.$router)
      })
      .then((response) => {
        // now sort through the items
        for (const category of this.categories) {
          if (category.id === 'kick_combos') {
            category.items = [];
            for (const item of response) {
              const db_items = item.kick_combos;
              for (const iteritem of db_items) {
                iteritem.tested = false;
                if (iteritem.kick_combos_to_ranks) {
                  iteritem.tested = iteritem.kick_combos_to_ranks.tested;
                }
                iteritem.value = iteritem.id;
                iteritem.text = iteritem.description;
                iteritem.name = iteritem.description;
                if (iteritem.tested){
                  category.items.push(iteritem);
                }
              }
            }
            break;
          }
        }
      })
      .catch((error) => {
        this.error_txt = 'Error getting Kick Combos: ' + error;
        this.openModal('error');
      });
    },
    getKicks() {
      return kicksAPIService.getKick(this.$router)
      .then((kicks_resp) => {

        kicks_resp.sort((a, b) => {
          if (a.order && b.order) {
            return a.order - b.order;
          }
          return 0;
        });

        for (const category of this.categories) {
          if (category.id === 'kicks') {
            category.dropdown_list = [];
            for (const kick of kicks_resp) {
              kick.value = kick.id;
              kick.text = kick.description;
              kick.name = kick.description;
              category.dropdown_list.push(kick);
            }
            break;
          }
        }
        return kicksAPIService.getKickByRank(this.userRank.id, this.$router)
      })
      .then((response) => {
        // now sort through the items
        for (const category of this.categories) {
          if (category.id === 'kicks') {
            category.items = [];
            for (const kicks_to_rank of response) {
              const kicks = kicks_to_rank.kicks;
              for (const kick of kicks) {
                kick.tested = false;
                if (kick.kicks_to_ranks) {
                  kick.tested = kick.kicks_to_ranks.tested;
                }
                kick.value = kick.id;
                kick.text = kick.description;
                kick.name = kick.description;
                if (kick.tested){
                  category.items.push(kick);
                }
              }
            }
            break;
          }
        }
      })
      .catch((error) => {
        this.error_txt = 'Error getting kicks: ' + error;
        this.openModal('error');
      });
    },
    getPoomsae() {
      return poomsaeAPIService.getPoomsae(this.$router)
      .then((poomsae_resp) => {

        poomsae_resp.sort((a, b) => {
          if (a.order && b.order) {
            return a.order - b.order;
          }
          return 0;
        });

        for (const category of this.categories) {
          if (category.id === 'poomsae') {
            category.dropdown_list = [];
            for (const poomsae of poomsae_resp) {
              poomsae.value = poomsae.id;
              poomsae.text = poomsae.description;
              poomsae.name = poomsae.description;
              category.dropdown_list.push(poomsae);
            }
            break;
          }
        }
        return poomsaeAPIService.getPoomsaeByRank(this.userRank.id, this.$router)
      })
      .then((response) => {
        // now sort through the items
        for (const category of this.categories) {
          if (category.id === 'poomsae') {
            category.items = [];
            for (const poomsae_to_rank of response) {
              const poomsaes = poomsae_to_rank.poomsaes;
              for (const poomsae of poomsaes) {
                poomsae.tested = false;
                if (poomsae.poomsae_to_ranks.tested) {
                  poomsae.tested = poomsae.poomsae_to_ranks.tested;
                }
                poomsae.value = poomsae.id;
                poomsae.text = poomsae.description;
                poomsae.name = poomsae.description;
                if (poomsae.tested){
                  category.items.push(poomsae);
                }
              }
            }
            break;
          }
        }
      })
      .catch((error) => {
        this.error_txt = 'Error getting poomsae: ' + error;
        this.openModal('error');
      });
    },
    getRanks() {
      return ranksAPIService.getRanks(this.$router)
      .then((response) => {
        this.ranks = response;
        for (let rank of this.ranks) {
          rank.text = rank.name;
          rank.value = rank.id;
          this.last_order = rank.order;
        }
      })
      .catch((error) => {
        this.error_txt = 'Error getting Ranks: ' + error;
        this.openModal('error');
      });
    },
    getRankTypes(){
      return rankTypesAPIService.getRankTypes(this.$router)
        .then((response) => {
          this.rank_types = response;
          for (let type of this.rank_types){
            if (type.name === "beginner"){
              this.limitors.beginner.id = type.id
            } else if (type.name === "intermediate"){
              this.limitors.intermediate.id = type.id
            } else if (type.name === "advanced"){
              this.limitors.advanced.id = type.id
            }
          }
        })
        .catch((error) => {
          this.error_txt = 'Error getting Rank Types: ' + error;
          this.openModal('error');
        });
    },
    getSelfDefense() {
      return selfDefenseTechniquesAPIService.getSelfDefenseTechniques(this.$router)
      .then((response) => {

        response.sort((a, b) => {
          if (a.order && b.order) {
            return a.order - b.order;
          }
          return 0;
        });

        for (const category of this.categories) {
          if (category.id === 'self_defense') {
            category.dropdown_list = [];
            for (const item of response) {
              item.value = item.id;
              item.text = item.description;
              item.name = item.description;
              category.dropdown_list.push(item);
            }
            break;
          }
        }
        return selfDefenseTechniquesAPIService.getSelfDefenseTechniquesByRank(this.userRank.id, this.$router)
      })
      .then((response) => {
        // now sort through the items
        for (const category of this.categories) {
          if (category.id === 'self_defense') {
            category.items = [];
            for (const item of response) {
              const db_items = item.self_defense_techniques;
              for (const iteritem of db_items) {
                iteritem.tested = false;
                if (iteritem.self_defense_techniques_to_ranks.tested) {
                  iteritem.tested = iteritem.self_defense_techniques_to_ranks.tested;
                }
                iteritem.value = iteritem.id;
                iteritem.text = iteritem.description;
                iteritem.name = iteritem.description;
                if (iteritem.tested){
                  category.items.push(iteritem);
                }
              }
            }
            break;
          }
        }
      })
      .catch((error) => {
        this.error_txt = 'Error getting Self Defense Items: ' + error;
        this.openModal('error');
      });
    },
    getBreakingTechniques() {
      return breakingTechniquesAPIService.getBreakingTechniques(this.$router)
      .then((technique_resp) => {
        for (const category of this.categories) {
          if (category.id === 'breaking_techniques') {
            category.dropdown_list = [];

            // Sort techniques by order
            technique_resp.sort((a, b) => {
              if (a.order && b.order) {
                return a.order - b.order;
              }
              return 0;
            });

            for (const technique of technique_resp) {
              technique.value = technique.id;
              technique.text = technique.name;
              category.dropdown_list.push(technique);
            }
            break;
          }
        }
        return breakingTechniquesAPIService.getBreakingTechniquesByRankID(this.userRank.id, this.$router)
      })
      .then((response) => {
        // now sort through the items
        for (const category of this.categories) {
          if (category.id === 'breaking_techniques') {
            category.items = [];
            for (const technique_to_rank of response) {
              const techniques = technique_to_rank.breaking_techniques;
              for (const technique of techniques) {
                technique.tested = false;
                if (technique.breaking_techniques_to_ranks) {
                  technique.tested = technique.breaking_techniques_to_ranks.tested;
                }
                technique.value = technique.id;
                technique.text = technique.name;
                if (technique.tested){
                  category.items.push(technique);
                }
              }
            }
            break;
          }
        }
      })
      .catch((error) => {
        this.error_txt = 'Error getting breaking techniques: ' + error;
        this.openModal('error');
      });
    },
    getTerminology() {
      return terminologyAPIService.getTerminology(this.$router)
      .then((response) => {
        for (const category of this.categories) {
          if (category.id === 'terminology') {
            category.dropdown_list = [];
            for (const item of response) {
              item.value = item.id;
              item.text = item.description;
              item.name = item.description;
              category.dropdown_list.push(item);
            }
            break;
          }
        }
        return terminologyAPIService.getTerminologyByRank(this.userRank.id, this.$router)
      })
      .then((response) => {
        // now sort through the items
        for (const category of this.categories) {
          if (category.id === 'terminology') {
            category.items = [];
            for (const item of response) {
              const db_items = item.terminologies;
              for (const iteritem of db_items) {
                iteritem.tested = false;
                if (iteritem.terminology_to_ranks.tested) {
                  iteritem.tested = iteritem.terminology_to_ranks.tested;
                }
                iteritem.value = iteritem.id;
                iteritem.text = iteritem.description;
                iteritem.name = iteritem.description;
                if (iteritem.tested){
                  category.items.push(iteritem);
                }
              }
            }
            break;
          }
        }
      })
      .catch((error) => {
        this.error_txt = 'Error getting Terminology: ' + error;
        this.openModal('error');
      });
    },
    getUserRankId(){
      return userAPIService.getUserById(this.userId, this.$router)
        .then((response) => {
          this.userRankId = response.rank_id
          this.userName = response.first_name + " " + response.last_name
          for (let rank of this.ranks){
            if (this.nextRankBool){
              this.nextRank = rank
              this.rankSelected()
              return
            }
            if (rank.id === this.userRankId){
              this.userRank = rank
              if (rank !== this.ranks[this.ranks.length - 1]){
                this.nextRankBool = true
              }
            }
          }
        })
        .catch((error) => {
          this.error_txt = "Error getting User: " + error;
          this.openModal('error')
        })
    },
    rankSelected() {
      this.getBreakingTechniques();
      this.getPoomsae();
      this.getKicks();
      this.getSelfDefense();
      this.getKickCombos();
      this.getTechniques();
      this.getTerminology();
      this.getDocumentation();
    },
    async onRankEnd(rank) {
      const allIds = [];
      for (let i = 0; i < this.ranks.length; i += 1) {
        const item = this.ranks[i];
        try {
          const parms = {
            order: i + 1,
          };
          await ranksAPIService.updateRank(item.id, parms, this.$router);
        } catch (e) {
          this.error_txt = 'Error updating rank order: ' + e;
          this.openModal('error');
        }
        allIds.push(item.id);
      }
    },
    openModal(name) {
      this.modals[name] = true
    },
    filterRanks(){
      this.filter_ranks= []
      for (let rank of this.ranks){
        if (rank.program_id === this.selected_program){
          this.filter_ranks.push(rank)
        }
      }
      for (let rank of this.filter_ranks){
        rank.selected = false
      }
      this.limited_ranks = this.filter_ranks
      this.$forceUpdate()
    },
    limitRanksFromTypes(){
      this.limited_ranks = [];
      for (let rank of this.filter_ranks) {
        if (rank.rank_type_id === this.limitors.beginner.id && this.limitors.beginner.value) {
          this.limited_ranks.push(rank);
        } else if (rank.rank_type_id === this.limitors.intermediate.id && this.limitors.intermediate.value) {
          this.limited_ranks.push(rank);
        } else if (rank.rank_type_id === this.limitors.advanced.id && this.limitors.advanced.value) {
          this.limited_ranks.push(rank);
        }
      }

      if (!this.limitors.beginner.value && !this.limitors.intermediate.value && !this.limitors.advanced.value){
        this.limited_ranks = this.filter_ranks
      }
    },
    updateLimitedRanks(){
      let temp = this.limited_ranks
      this.limited_ranks = []
      for (let rank of temp) {
        this.limited_ranks.push(rank)
      }
    },
    selectAllCheck(){
      this.selectAll = !this.selectAll
      let temp = this.limited_ranks
      this.limited_ranks = []
      for (let rank of temp) {
        rank.selected = this.selectAll
        this.limited_ranks.push(rank)
      }
    },
  },

};
</script>

<style scoped>
.customTitle{
  color: black;
}
</style>
