import CommonAPIService from './CommonHandler';

export class KickCombosAPIService {
  constructor() {
    this.search = '';
    this.CommonAPIService = new CommonAPIService();
  }

  // gets all the kick_combos
  getKickCombos(router) {
    const url = '/api/kick_combos';
    return this.CommonAPIService.getCall(url, '', router);
  }

  getKickCombosByRank(rank_id, router) {
    const url = `/api/kick_combos/${rank_id}/rank`;
    return this.CommonAPIService.getCall(url, '', router);
  }
  getKickCombosByUnit(unit_id, router) {
    const url = `/api/kick_combos/${unit_id}/unit`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  saveKickCombosToTechniques(techniques, router) {
    const url = `/api/kick_combos/technique`;
    return this.CommonAPIService.postCall(url, {techniques: techniques}, router);
  }

  getKickCombosByID(kick_combos_id, router) {
    const url = `/api/kick_combos/${kick_combos_id}`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  saveKickCombosToRank(ranks, router) {
    const url = '/api/kick_combos/rank';
    return this.CommonAPIService.postCall(url, {ranks: ranks}, router);
  }

  addKickCombos(kick_combos, router) {
    const url = '/api/kick_combos';
    return this.CommonAPIService.postCall(url, kick_combos, router);
  }

  updateKickCombos(kick_combo_id, parms, router) {
    const url = `/api/kick_combos/${kick_combo_id}`;
    return this.CommonAPIService.putCall(url, parms, router);
  }
  updateKickCombosToRank(data, rank_id, router) {
    const url = `/api/kick_combos/${rank_id}/torank`;
    return this.CommonAPIService.putCall(url, data, router);
  }
  updateKickCombosToUnit(data, unit_id, router) {
    const url = `/api/kick_combos/${unit_id}/tounit`;
    return this.CommonAPIService.putCall(url, data, router);
  }
  linkKickComboToRank(kick_combo_id, rank_id, router) {
    const url = `/api/kick_combos/${kick_combo_id}/${rank_id}/rank`;
    return this.CommonAPIService.getCall(url, '', router);
  }
  linkKickComboToUnit(kick_combo_id, unit_id, router) {
    const url = `/api/kick_combos/${kick_combo_id}/${unit_id}/unit`;
    return this.CommonAPIService.getCall(url, '', router);
  }
  deleteKickCombosToRank(kick_combo_id, rank_id, router) {
    const url = `/api/kick_combos/${kick_combo_id}/${rank_id}/rank`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }
  deleteKickCombosToUnit(kick_combo_id, unit_id, router) {
    const url = `/api/kick_combos/${kick_combo_id}/${unit_id}/unit`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }

  deleteKickCombos(kick_combo_id, router) {
    const url = `/api/kick_combos/${kick_combo_id}`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }
  removeKickMotion(kick_combo_id, type, item_id, router) {
    const url = `/api/kick_combos/${kick_combo_id}/${item_id}/${type}/kickmotion`;
    return this.CommonAPIService.deleteCall(url, null, router);
  }
  addKickMotion(kick_combo_id, parms, router) {
    const url = `/api/kick_combos/${kick_combo_id}/kickmotion`;
    return this.CommonAPIService.postCall(url, parms, router);
  }

  updateTestedOnKickCombo(kick_combo_id, rank_id, tested, router) {
    const url = `/api/kick_combos/${kick_combo_id}/${rank_id}/${tested}/tested/rank`;
    return this.CommonAPIService.putCall(url, null, router);
  }
  updateTestedOnKickComboUnit(kick_combo_id, unit_id, tested, router) {
    const url = `/api/kick_combos/${kick_combo_id}/${unit_id}/${tested}/tested/unit`;
    return this.CommonAPIService.putCall(url, null, router);
  }
}

export default KickCombosAPIService;
