import {nyalaNormalBase64} from "../../Pages/Admin/Fonts/nyala-normal";
import {lastNinjaBase64} from "../../Pages/Admin/Fonts/lastninja";
import {nyalaBoldBase64} from "../../Pages/Admin/Fonts/nyala-bold";
import {parchmentNormalBase64} from "../../Pages/Admin/Fonts/Parchment MF-normal";
import JsPDF from 'jspdf';

export async function printBBTestingSheets() {

  const doc = new JsPDF({
    orientation: "portrait",
    format: 'letter',
  });
  let empty = true
  let count = 0

  function addFontToPDF(pdf) {
    pdf.addFileToVFS('nyala.ttf', nyalaNormalBase64);
    pdf.addFont('nyala.ttf', 'nyala', 'normal');
    pdf.addFileToVFS('lastninja.ttf', lastNinjaBase64);
    pdf.addFont('lastninja.ttf', 'lastninja', 'normal');
    pdf.addFileToVFS('nyala-bold.ttf', nyalaBoldBase64);
    pdf.addFont('nyala-bold.ttf', 'nyala', 'bold');
    pdf.addFileToVFS('nyala-italics.ttf', nyalaBoldBase64);
    pdf.addFont('nyala-italics.ttf', 'nyala', 'italic');
    pdf.addFileToVFS('Parchment.ttf', parchmentNormalBase64);
    pdf.addFont('Parchment.ttf', 'Parchment', 'normal');
  }

  addFontToPDF(doc)

  for (let order of this.givenData){
    let index = this.givenData.indexOf(order) + 1
    this.precentage = (index / this.givenData.length) * 100
    this.precentage = parseFloat((Math.round(this.precentage * 100) / 100).toFixed(1))

    if (this.checkSelected(order.id) || this.givenData.length === 1) {
      empty = false
      count++
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const halfPage = pageWidth / 2;
      let x = 20;
      let y = 20;

      function printTopInfo(rank, tape, trail){
        x = 20
        doc.setFontSize('20')
        doc.setFont('times', 'bold')
        doc.text(tape, x + 10, y)
        doc.setFont('times', 'normal')
        doc.text(trail, pageWidth - 30, y, 'right')
        y += 10

        let textWidth = doc.getTextWidth('Name:')
        doc.setFontSize('14')
        doc.setFont('times', 'bold')
        doc.text("Name:", x, y);
        doc.setFont('nyala', 'normal')
        doc.text(order.user.first_name + " " + order.user.last_name, x + (textWidth + 1), y);
        x = pageWidth - 20
        textWidth = doc.getTextWidth(rank.name)
        doc.setFont('times', 'bold')
        doc.text("Current Rank:", x - (textWidth + 1), y, 'right')
        doc.setFont('nyala', 'normal')
        doc.text(rank.name, x, y, 'right')
        y += 1
        doc.setFont('times', 'bold')
        textWidth = doc.getTextWidth('Name:')
        x = 20
        doc.line(x + textWidth, y, halfPage, y);
        doc.setFont('nyala', 'normal')
        textWidth = doc.getTextWidth(rank.name)
        doc.line(pageWidth - textWidth - 20, y, pageWidth - 20, y);
      }

      this.categories= [
        {
          id: 'kicks',
          name: 'Kicking',
          items: [],
        },
        {
          id: 'kick_combos',
          name: 'Kicking Combos',
          items: [],
        },
        {
          id: 'poomsae',
          name: 'Poomsae',
          items: [],
        },
        {
          id: 'techniques',
          name: 'Techniques',
          items: [],
        },
        {
          id: 'self_defense',
          name: 'Self Defence',
          items: [],
        },
        {
          id: 'sparring',
          name: 'Sparring',
          items: [
            {
              name: 'Olympic Sparring'
            },
            {
              name: 'Free Sparring'
            },
          ],
        },
      ]

      for (let category of this.categories) {
        let height = 21;
        height += ((category.items.length / 2) + 1) * 10
        height += 28
        if (y > (pageHeight - height - 20)){
          y = 20
          x = 20
          doc.addPage()
        }
        let rectSize
        switch (category.id) {
          case "poomsae":
            await this.getPoomsaeByRankId(order.rank.id)
            break
          case "kicks":
            await this.getKicksByRankId(order.rank.id)
            break
          case "self_defense":
            await this.getSelfDefByRankId(order.rank.id)
            break
          case "kick_combos":
            await this.getKickCombosByRankId(order.rank.id)
            break
          case "techniques":
            await this.getTechsByRankId(order.rank.id)
            break
        }
        let startY = y + 20
        let left = true
        let lastSide = left
        if (category.items.length > 0) {
          printTopInfo(order.rank, category.name, 'Trial 1')
          for (let item of category.items) {
            y += 10
            if (!left || (category.items.indexOf(item) >= category.items.length / 2)){
              x = halfPage + 5
              left = false
            } else {
              x = 15
            }
            if (left !== lastSide){
              y = startY
            }
            lastSide = left

            doc.setFontSize('12')
            rectSize = 8
            doc.line(x, y + 2, x + rectSize, y + 2);
            doc.rect(x + rectSize, y - 4, rectSize, rectSize - 2)
            doc.setFont('nyala', 'normal')
            let originalY = y
            if (doc.getTextWidth(item.name) >= 95){
              let splitName = item.name.split(' ')
              let builtName = splitName[0] + ' '
              for (let i = 1; i < splitName.length; i++){
                if (doc.getTextWidth(builtName + splitName[i] + ' ') >= 80){
                  doc.text(builtName, x + 20, y)
                  y += 5
                  builtName = splitName[i] + ' '
                } else {
                  builtName += splitName[i] + ' '
                }
              }
              doc.text(builtName, x + 20, y)
            } else {
              doc.text(item.name, x + 20, y)
            }
          }
        }
        y += 10

        if (category.items.length > 1 && category.items.length % 2){
          y += 10
        }
        rectSize = 160
        doc.setFillColor(256, 256, 256);
        let paddedRect = halfPage - (rectSize / 2) + 3
        x = halfPage - (rectSize / 2)
        doc.rect(x, y, rectSize, rectSize / 8, 'DF')
        y += 3
        doc.text("Grader Comments:", x + 1, y + 1)
        y += (rectSize / 8) + 5

        doc.setFontSize('14')
        doc.setFont('times', 'bold')
        let textWidth = doc.getTextWidth('Grader:')
        doc.text('Grader:', x + 10, y)
        doc.line(x + 10 + textWidth, y + 1, halfPage, y + 1);
        textWidth = doc.getTextWidth('Date:')
        doc.text('Date:', 10 + halfPage, y)
        doc.line(10 + halfPage + textWidth, y + 1, pageWidth - 30, y + 1);
        y += 25
      }

      if (count < this.selectedData.length){
        doc.addPage();
      }
    }
  }

  if (!empty) {
    let fileName = this.printName + " Testing Sheets.pdf"
    doc.save(fileName);
  } else {
    this.error_txt = "No Competitors selected"
    this.openModal('error')
  }
}
