import FileAPIService from "../../../../servicehandlers/FileHandler";
const fileAPI = new FileAPIService();
export async function downloadPDF(name, type, id) {
  this.filename = name;
  this.spinning = true;
  return fileAPI.getPDFFile(name, type, id, this.$router)
    .then((response) => {
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = this.filename;
      this.spinning = false;
      link.click();
    })
    .catch((error) => {
      this.error_txt = 'Step Sparring PDF download failed! ' + error;
      this.openModal('error');
      this.spinning = false;
    })
}
