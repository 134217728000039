import JsPDF from 'jspdf';
import {nyalaNormalBase64} from "../../Pages/Admin/Fonts/nyala-normal";
import {lastNinjaBase64} from "../../Pages/Admin/Fonts/lastninja";
import {nyalaBoldBase64} from "../../Pages/Admin/Fonts/nyala-bold";
import {parchmentNormalBase64} from "../../Pages/Admin/Fonts/Parchment MF-normal";
export async function printRollSheets() {

  const doc = new JsPDF({
    orientation: "portrait",
    format: 'letter',
  });
  let fileName = this.printName
  let empty = true
  let count = 0

  function addFontToPDF(pdf) {
    pdf.addFileToVFS('nyala.ttf', nyalaNormalBase64);
    pdf.addFont('nyala.ttf', 'nyala', 'normal');
    pdf.addFileToVFS('lastninja.ttf', lastNinjaBase64);
    pdf.addFont('lastninja.ttf', 'lastninja', 'normal');
    pdf.addFileToVFS('nyala-bold.ttf', nyalaBoldBase64);
    pdf.addFont('nyala-bold.ttf', 'nyala', 'bold');
    pdf.addFileToVFS('nyala-italics.ttf', nyalaBoldBase64);
    pdf.addFont('nyala-italics.ttf', 'nyala', 'italic');
    pdf.addFileToVFS('Parchment.ttf', parchmentNormalBase64);
    pdf.addFont('Parchment.ttf', 'Parchment', 'normal');
  }

  addFontToPDF(doc)

  doc.setFontSize('10');
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const halfPage = pageWidth / 2;
  let x = 10;
  let y = 10;

  function printTopInfo(printName, programName){
    let string = printName + ' - ' + programName + ' - Roll Sheet'
    doc.setFontSize('16')
    y = 10
    x = 10
    doc.setFont('times', 'bold')
    let textWidth = doc.getTextWidth(string)
    doc.text(string, halfPage - (textWidth / 2), y);
    doc.setFont('times', 'normal')
    doc.setFontSize('10')
    y += 8;
    doc.text('First Name', x + 40, y)
    doc.text('Last Name', x + 70, y)
    doc.text('Gender', x + 100, y)
    doc.text('Age', x + 125, y)
    doc.text('Current Rank', x + 140, y)
    y += 10
  }

  if (this.printByTypes[this.printType].name === 'Program'){
    fileName += ' Programs '
    for (let id of this.selectedData){
      let index = this.selectedData.indexOf(id) + 1
      this.precentage = (index / this.selectedData.length) * 100
      this.precentage = parseFloat((Math.round(this.precentage * 100) / 100).toFixed(1))

      empty = false
      count++

      let programName = ''
      for (let item of this.filteredData){
        if (item.id === id){
          programName = item.name
          break
        }
      }
      printTopInfo(this.printName, programName)


      let dataArray = this.givenData.filter(e => e.program.id === id)
      dataArray.sort((a, b) => a.rank.order - b.rank.order);

      let programPageCount = 1
      let totalPageCount = Math.ceil(dataArray.length / 35)
      let newPage = true
      for (let order of dataArray){
        if (y > (pageHeight - 10)){
          programPageCount++
          doc.addPage()
          printTopInfo(this.printName, programName)
          newPage = true
        }
        if (newPage){
          doc.setFontSize('8')
          doc.text(programName + ' Page ' + programPageCount + '/' + totalPageCount, 10, 10)
          newPage = false
        }
        doc.setFontSize('12')
        let rectSize = 5
        doc.rect(x + 30, y - 4, rectSize, rectSize)
        doc.text(order.user.first_name, x + 40, y)
        doc.text(order.user.last_name, x + 70, y)
        if (order.user.gender){
          doc.text(order.user.gender, x + 100, y)
        } else {
          doc.text('Unknown', x + 100, y)
        }
        doc.text(JSON.stringify(order.user.age), x + 125, y)
        doc.text(order.rank.name, x + 140, y)
        y+=7
      }

      if (count !== this.selectedData.length){
        doc.addPage();
      }
    }
  }
  else if (this.printByTypes[this.printType].name === 'Rank'){
    fileName += ' Ranks '
    for (let id of this.selectedData){
      let index = this.selectedData.indexOf(id) + 1
      this.precentage = (index / this.selectedData.length) * 100
      this.precentage = parseFloat((Math.round(this.precentage * 100) / 100).toFixed(1))

      empty = false
      count++

      let rankName = ''
      for (let item of this.filteredData){
        if (item.id === id){
          rankName = item.name
          break
        }
      }
      printTopInfo(this.printName, rankName)


      let dataArray = this.givenData.filter(e => e.rank.id === id)
      dataArray.sort((a, b) => a.rank.order - b.rank.order);

      let rankPageCount = 1
      let totalPageCount = Math.ceil(dataArray.length / 35)
      let newPage = true
      for (let order of dataArray){
        if (y > (pageHeight - 10)){
          rankPageCount++
          doc.addPage()
          printTopInfo(this.printName, rankName)
          newPage = true
        }
        if (newPage){
          doc.setFontSize('8')
          doc.text(rankName + ' Page ' + rankPageCount + '/' + totalPageCount, 10, 10)
          newPage = false
        }
        doc.setFontSize('12')
        let rectSize = 5
        doc.rect(x + 30, y - 4, rectSize, rectSize)
        doc.text(order.user.first_name, x + 40, y)
        doc.text(order.user.last_name, x + 70, y)
        if (order.user.gender){
          doc.text(order.user.gender, x + 100, y)
        } else {
          doc.text('Unknown', x + 100, y)
        }
        doc.text(JSON.stringify(order.user.age), x + 125, y)
        doc.text(order.rank.name, x + 140, y)
        y+=7
      }

      if (count !== this.selectedData.length){
        doc.addPage();
      }
    }
  }
  else if (this.printByTypes[this.printType].name === 'Groups'){
    fileName += ' Groups '
    for (let id of this.selectedData){
      let index = this.selectedData.indexOf(id) + 1
      this.precentage = (index / this.selectedData.length) * 100
      this.precentage = parseFloat((Math.round(this.precentage * 100) / 100).toFixed(1))

      empty = false
      count++

      let typeName = ''
      for (let item of this.filteredData){
        if (item.id === id){
          typeName = item.name
          break
        }
      }
      printTopInfo(this.printName, typeName)


      let dataArray = this.givenData.filter(e => e.testing_type_id === id)
      dataArray.sort((a, b) => a.rank.order - b.rank.order);

      let typePageCount = 1
      let totalPageCount = Math.ceil(dataArray.length / 35)
      let newPage = true
      for (let i = 1; i <= 5; i++){
        if (i !== 1){
          y += 10
        }
        doc.setFontSize('12')
        doc.text('Group ' + i, 10, y)
        for (let order of dataArray){
          if (order.group.group_id === i) {
            if (y > (pageHeight - 10)){
              typePageCount++
              doc.addPage()
              printTopInfo(this.printName, typeName)
              newPage = true
            }
            if (newPage){
              doc.setFontSize('8')
              doc.text(typeName + ' Page ' + typePageCount + '/' + totalPageCount, 10, 10)
              newPage = false
            }
            doc.setFontSize('12')
            let rectSize = 5
            doc.rect(x + 30, y - 4, rectSize, rectSize)
            doc.text(order.user.first_name, x + 40, y)
            doc.text(order.user.last_name, x + 70, y)
            if (order.user.gender){
              doc.text(order.user.gender, x + 100, y)
            } else {
              doc.text('Unknown', x + 100, y)
            }
            doc.text(JSON.stringify(order.user.age), x + 125, y)
            doc.text(order.rank.name, x + 140, y)
            y+=7
          }
        }
      }

      if (count !== this.selectedData.length){
        doc.addPage();
      }
    }
  }
  else if (this.printByTypes[this.printType].name === 'Rank Level'){
    fileName += ' Rank Levels '
    for (let id of this.selectedData){
      let index = this.selectedData.indexOf(id) + 1
      this.precentage = (index / this.selectedData.length) * 100
      this.precentage = parseFloat((Math.round(this.precentage * 100) / 100).toFixed(1))

      empty = false
      count++

      let typeName = ''
      for (let item of this.filteredData){
        if (item.id === id){
          typeName = item.name
          break
        }
      }
      printTopInfo(this.printName, typeName)


      let dataArray = this.givenData.filter(e => e.rank.rank_type_id === id)
      dataArray.sort((a, b) => a.rank.order - b.rank.order);

      let typePageCount = 1
      let totalPageCount = Math.ceil(dataArray.length / 35)
      let newPage = true
      for (let order of dataArray){
        if (y > (pageHeight - 10)){
          typePageCount++
          doc.addPage()
          printTopInfo(this.printName, typeName)
          newPage = true
        }
        if (newPage){
          doc.setFontSize('8')
          doc.text(typeName + ' Page ' + typePageCount + '/' + totalPageCount, 10, 10)
          newPage = false
        }
        doc.setFontSize('12')
        let rectSize = 5
        doc.rect(x + 30, y - 4, rectSize, rectSize)
        doc.text(order.user.first_name, x + 40, y)
        doc.text(order.user.last_name, x + 70, y)
        if (order.user.gender){
          doc.text(order.user.gender, x + 100, y)
        } else {
          doc.text('Unknown', x + 100, y)
        }
        doc.text(JSON.stringify(order.user.age), x + 125, y)
        doc.text(order.rank.name, x + 140, y)
        y+=7
      }

      if (count !== this.selectedData.length){
        doc.addPage();
      }
    }
  }
  else if (this.printByTypes[this.printType].name === 'Tests'){
    fileName += ' Tests '
    for (let id of this.selectedData){
      let index = this.selectedData.indexOf(id) + 1
      this.precentage = (index / this.selectedData.length) * 100
      this.precentage = parseFloat((Math.round(this.precentage * 100) / 100).toFixed(1))

      empty = false
      count++

      let typeName = ''
      for (let item of this.filteredData){
        if (item.id === id){
          typeName = item.name
          break
        }
      }
      printTopInfo(this.printName, typeName)


      let dataArray = this.givenData.filter(e => e.testing_type_id === id)
      dataArray.sort((a, b) => a.rank.order - b.rank.order);

      let typePageCount = 1
      let totalPageCount = Math.ceil(dataArray.length / 35)
      let newPage = true
      doc.setFontSize('12')
      for (let order of dataArray){
        if (y > (pageHeight - 10)){
          typePageCount++
          doc.addPage()
          printTopInfo(this.printName, typeName)
          newPage = true
        }
        if (newPage){
          doc.setFontSize('8')
          doc.text(typeName + ' Page ' + typePageCount + '/' + totalPageCount, 10, 10)
          newPage = false
        }
        doc.setFontSize('12')
        let rectSize = 5
        doc.rect(x + 30, y - 4, rectSize, rectSize)
        doc.text(order.user.first_name, x + 40, y)
        doc.text(order.user.last_name, x + 70, y)
        if (order.user.gender){
          doc.text(order.user.gender, x + 100, y)
        } else {
          doc.text('Unknown', x + 100, y)
        }
        doc.text(JSON.stringify(order.user.age), x + 125, y)
        doc.text(order.rank.name, x + 140, y)
        y+=7
      }

      if (count !== this.selectedData.length){
        doc.addPage();
      }
    }
  }

  if (!empty) {
    fileName += " Roll Sheets.pdf"
    doc.save(fileName);
  } else {
    this.error_txt = "No Competitors selected"
    this.openModal('error')
  }
}
