import JsPDF from 'jspdf';
import OutlineAPIService from "../../../../servicehandlers/OutlinesAPIService";
const outlineAPIService = new OutlineAPIService();
export async function printOutlines() {

  let scores_objects = {};
  // pull the required items we will be needing from the DB
  const outlines = [];

  for (const data of this.givenData) {
    if (this.selectedData.includes(data.id)) {
      if (data.outline){
        await outlineAPIService.getOutline(data.outline.id, this.$router)
          .then((outline) => {
            let temp = {
              outline: outline,
              rank: data.rank,
            }
            outlines.push(temp);
          })
          .catch((error) => {
            this.error_txt = 'Lock failed: ' + error;
            this.openModal('error');
          });
      } else {
        this.error_txt = 'One or more of the selected competitors is missing an outline'
        this.openModal('error')
        this.loading = false
        return
      }
    }
  }
  return Promise.all(outlines)
    .then(() => {
      return outlineAPIService.getBase64File('pdf_titles.png', this.$router)
    })
    .then((response) => {
      const doc = new JsPDF({
        orientation: "landscape",
        format: 'letter',
      });
      doc.setTextColor(0, 0, 0);
      const imgData = response;
      let count = 0
      const pageHeight = doc.internal.pageSize.getHeight();
      const pageWidth = doc.internal.pageSize.getWidth();
      const halfPage = pageWidth / 2;

      for (const data of outlines) {
        let index = outlines.indexOf(data) + 1
        this.precentage = (index / outlines.length) * 100
        this.precentage = parseFloat((Math.round(this.precentage * 100) / 100).toFixed(1))

        count++
        let outline = data.outline
        scores_objects = {};
        this.setTechniqueNames(outline);
        // get all the scores into a nice bundle so we can print it
        scores_objects = this.calculateScoresForOutline(outline);

        // set the description in the center
        let x = 118;
        let y = 10;
        doc.text(outline.description, x, y);
        x = 10;
        y = 10;

        doc.setFontSize('12');
        doc.text(outline.user.first_name + ' ' + outline.user.last_name + '-' + outline.weight + ' lbs,  Weight Class ' + scores_objects.userClass.value + ', ' + outline.user.age + ' years', x, y);

        x = 200
        doc.text('Average Board Size: ' + scores_objects.userClass.avgBrdSize.name, x, y)

        x = 10
        outline.updatedAt = new Date(outline.updatedAt)

        let textWidth = doc.getTextWidth('Last saved: ' + outline.updatedAt.toLocaleDateString())
        doc.text('Last saved: ' + outline.updatedAt.toLocaleDateString(), pageWidth - textWidth - 10, pageHeight - 10)

        y += 5;
        x = 10;
        doc.text('Required Number of Breaks: ' + data.rank.number_of_breaks, x, y);
        x += 70;
        doc.text('Required Speed Breaks: ' + data.rank.required_speed_breaks, x, y);
        x += 60;
        doc.text('Required Power Breaks: ' + data.rank.required_power_breaks, x, y);
        x += 60;
        doc.text('Breaking Point Minimum: ' + data.rank.breaking_point_minimum, x, y);
        x = 10;
        y += 5;

        // now display the requirements met objects
        if (outline.required_number_breaks) {
          doc.setTextColor(0, 255, 0);
          doc.text('Have: ' + outline.breaking_techniques_to_outlines.length, x, y);
        } else {
          doc.setTextColor(255, 0, 0);
          doc.text('Have: ' + outline.breaking_techniques_to_outlines.length, x, y);
        }
        x += 70;

        if (outline.required_speed_breaks) {
          doc.setTextColor(0, 255, 0);
          doc.text('Have: ' + scores_objects.total_speed_breaks, x, y);
        } else {
          doc.setTextColor(255, 0, 0);
          doc.text('Have: ' + scores_objects.total_speed_breaks, x, y);
        }
        x += 60;

        if (outline.required_power_breaks) {
          doc.setTextColor(0, 255, 0);
          doc.text('Have: ' + scores_objects.total_power_breaks, x, y);
        } else {
          doc.setTextColor(255, 0, 0);
          doc.text('Have: ' + scores_objects.total_power_breaks, x, y);
        }
        x += 60;

        if (outline.required_minimum_score) {
          doc.setTextColor(0, 255, 0);
          doc.text('Have: ' + scores_objects.total_average_score, x, y);
        } else {
          doc.setTextColor(255, 0, 0);
          doc.text('Have: ' + scores_objects.total_average_score, x, y);
        }

        doc.setTextColor(0, 0, 0);
        doc.setFontSize('8');
        // set each technique column up, one by one
        x = 10;
        y += 30;
        doc.text('Order', x, y);
        x += 17;
        doc.text('Technique', x, y);
        x += 90;
        doc.text('Board Size', x, y);
        x += 23;
        doc.text('# of Boards', x, y);
        x += 23;
        y -= 25;
        doc.addImage(imgData, 'PNG', x, y, 60, 30);
        y += 25;
        x += 66;
        doc.text('Score', x, y);

        for (const technique of outline.breaking_techniques_to_outlines) {
          const techniqueDirections = technique.breaking_technique.directions;
          const direction_id = technique.direction_id;
          const board_id = technique.board_id;
          const modifier_id = technique.dropdown_modifier_id;
          x = 10;
          y += 10;
          doc.text(technique.order.toString(), x, y);
          x += 17;
          let technique_string = '';
          for (const modifier of this.dropdown_support_options) {
            if (modifier.id === modifier_id) {
              technique_string += modifier.description + " ";
              break;
            }
          }
          technique_string += technique.breaking_technique.name + " ";

          for (const dir of techniqueDirections) {
            if (dir.id === direction_id) {
              if (dir.id !== null && dir.name !== "N/A") {
                technique_string += dir.name + " ";
              }
              break;
            }
          }
          if (technique_string.length < 65) {
            doc.text(technique_string, x, y);
          } else {
            doc.text(technique_string.substring(0, 65) + '-', x, y)
            doc.text(technique_string.substring(65), x, y + 5)
          }
          x += 90;

          for (const board of this.dropdown_boards) {
            if (board.id === board_id) {
              doc.text(board.name, x, y);
              break;
            }
          }
          x += 23;

          doc.text(technique.num_boards.toString(), x, y);
          x += 24;

          doc.rect(x, y - 3, 5, 5);
          if (technique.self_held) {
            doc.text('X', x + 2, y + 1);
          }
          x += 10;

          doc.rect(x, y - 3, 5, 5);
          if (technique.blindfolded) {
            doc.text('X', x + 2, y + 1);
          }
          x += 10;

          doc.rect(x, y - 3, 5, 5);
          if (technique.simultaneous) {
            doc.text('X', x + 2, y + 1);
          }
          x += 12;

          doc.rect(x, y - 3, 5, 5);
          if (technique.successive) {
            doc.text('X', x + 2, y + 1);
          }
          x += 12;

          doc.rect(x, y - 3, 5, 5);
          if (technique.no_spacers) {
            doc.text('X', x + 2, y + 1);
          }
          x += 23;

          // print the score out
          doc.text(scores_objects[technique.id].line_score.toString(), x, y);
        }

        // now put the totals in at the bottom
        // start with total boards
        y += 10;
        x = 10 + 17 + 75 + 23;
        doc.text("Total Boards: " + scores_objects.total_boards.toString(), x, y);
        x += 24 + 10 + 10 + 12 + 12 + 11 + 12;
        doc.text('Possible Score: ' + scores_objects.total_average_score.toString(), x, y);

        y += 10;
        x = 10;

        let yO = y

        const boards = {};
        doc.text('Total Board Breakdown: ', x, y);
        for (const board of this.dropdown_boards) {
          boards[board.id] = {
            name: board.name,
            total: 0,
          };
        }
        y += 6;
        for (const technique_to_outline of outline.breaking_techniques_to_outlines) {
          // first get the number of the board, add all them together
          boards[technique_to_outline.board_id].total += parseInt(technique_to_outline.num_boards);
        }
        const keys = Object.keys(boards);
        for (const key of keys) {
          const board = boards[key];
          if (board.name !== 'N/A') {
            x = 10;
            doc.text(board.name + 's: ' + board.total.toString(), x, y);
            y += 6;
          }
        }

        y = yO
        x = halfPage
        doc.text('Break types: ', x, y);
        y += 6
        doc.text('# of Speed Breaks: ' + scores_objects.total_speed_breaks, x, y);
        y += 6
        doc.text('# of Power Breaks: ' + scores_objects.total_power_breaks, x, y);
        y += 6
        doc.text('Breaks below average: ' + scores_objects.boards_below_avg, x, y);
        y += 6
        doc.text('Breaks well above average: ' + scores_objects.boards_above_avg, x, y);
        x += 24;

        // add 1 page per user, just like it shows right now
        if (count !== this.selectedData.length){
          doc.addPage();
        }
      }
      let fileName = this.printName + ' Outlines Sheet.pdf'
      doc.save(fileName);
    })
    .catch((error) => {
      this.loading = false;
      this.error_txt = 'Error: ' + error;
      this.openModal('error');
    });
}
