<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <div slot="header">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-8">
                <h4 class="card-title">Units</h4>
                <p class="card-category">
                  Create or Edit Units
                </p>
                <p class="card-category">
                  <el-button type="primary" @click="openModal('create')" icon="">Add New Unit</el-button>
                </p>
              </div>
              <div class="col-sm-4 align-content-end">
<!--                <master-print-dialog :type="'rank-curriculum'" :given-data="units" :print-name="'Units'"></master-print-dialog>-->
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row align-items-center">
              <div class="col-sm-8">
                <el-input v-model="search" placeholder="Search" @keyup.native.enter="filterUnits"></el-input>
              </div>
              <div class="col-sm-4" style="padding-left: 5px;">
                <el-button type="primary" @click="filterUnits" icon="">Search</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-table stripe :data="filteredUnits" style="width: 100%" height="65vh" @sort-change="handleSortChange" :row-class-name="tableRowClassName">>
        <el-table-column prop="order" sortable="custom" label="Order" :min-width="200" align="center">
          <template v-slot="scope">
            <div class="row" :class="tableRowClassName(scope.row.is_sub_unit)">
              <div class="col-sm-4">
                {{ scope.row.order }}
              </div>
              <div class="col-sm-8" v-if="search.length > 0">
                <div class="row">
                  <div class="col-sm-6">
                    <el-tooltip effect="dark" content="Remove Search to Reorder Units">
                      <el-button disabled type="text" icon="el-icon-arrow-up"/>
                    </el-tooltip>
                  </div>
                  <div class="col-sm-6">
                    <el-tooltip effect="dark" content="Remove Search to Reorder Units">
                      <el-button disabled type="text" icon="el-icon-arrow-down" />
                    </el-tooltip>
                  </div>
                </div>
              </div>
              <div class="col-sm-8" v-else>
                  <div class="row">
                    <div class="col-sm-6">
                        <el-button type="text" icon="el-icon-arrow-up" @click="moveUp(scope.$index)" />
                    </div>
                    <div class="col-sm-6">
                        <el-button type="text" icon="el-icon-arrow-down" @click="moveDown(scope.$index)" />
                    </div>
                  </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" sortable="custom" label="Name" :min-width="150" align="center">
          <template v-slot="scope">
            <el-input v-model="scope.row.name" placeholder="Name" @change="updateUnit(scope.row)"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="Breaking Requirements" :min-width="300" align="center">
          <template v-slot="scope">
            <div class="row">
              <div class="col-sm-6">
                <label>Number of Breaks:</label>
                <el-input v-model="scope.row.number_of_breaks" min="0" type="number" @change="updateUnit(scope.row)"></el-input>
              </div>
              <div class="col-sm-6">
                <label>Breaking Point Minimum:</label>
                <el-input v-model="scope.row.breaking_point_minimum" min="0" type="number" @change="updateUnit(scope.row)"></el-input>
              </div>
              <div class="col-sm-6">
                <label>Required Speed Breaks:</label>
                <el-input v-model="scope.row.required_speed_breaks" min="0" type="number" @change="updateUnit(scope.row)"></el-input>
              </div>
              <div class="col-sm-6">
                <label>Required Power Breaks:</label>
                <el-input v-model="scope.row.required_power_breaks" min="0" type="number" @change="updateUnit(scope.row)"></el-input>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Break Options" :min-width="150" align="center">
          <template v-slot="scope">
            <el-switch style="height: 50px" v-model="scope.row.freestyle_breaks" active-text="Freestyle" inactive-text="Assigned" @change="toggleBreaksAssign(scope.row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center">
          <template v-slot="scope">
            <el-button type="danger" @click="deleteUnit(scope.row.id)" icon="el-icon-delete"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </card>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="closeModal('error')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Create New Unit"
      :visible.sync="modals.create">
      <div class="text-center">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12">
                <label>Name:</label>
                <el-input v-model="new_unit.name" placeholder="Name"></el-input>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12 text-center align-items-center">
                <el-switch style="height: 50px" v-model="new_unit.freestyle_breaks" active-text="Freestyle" inactive-text="Assigned"></el-switch>
              </div>
            </div>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-sm-3">
                <label>Number of Breaks:</label>
                <el-input v-model="new_unit.number_of_breaks" min="0" type="number"></el-input>
              </div>
              <div class="col-sm-3">
                <label>Break Point Min:</label>
                <el-input v-model="new_unit.breaking_point_minimum" min="0" type="number"></el-input>
              </div>
              <div class="col-sm-3">
                <label>Speed Breaks:</label>
                <el-input v-model="new_unit.required_speed_breaks" min="0" type="number"></el-input>
              </div>
              <div class="col-sm-3">
                <label>Power Breaks:</label>
                <el-input v-model="new_unit.required_power_breaks" min="0" type="number"></el-input>
              </div>
            </div>
          </div>
        </div>
        <br>
        <div slot="footer" class="dialog-footer text-center">
          <el-button type="success" @click="createUnit('create')" icon="">Create</el-button>
          <el-button type="danger" @click="closeModal('create')" icon="">Cancel</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>{{ success_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="closeModal('success')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Change User Units"
      :visible.sync="modals.users">
      <div class="text-center" v-if="users.length > 0">
        These users are assigned the unit of {{delete_unit.name}}
        <el-table stripe :data="users" style="width: 100%">
          <el-table-column label="Name" :min-width="150" align="center">
            <template v-slot="scope">
              {{scope.row.first_name}} {{scope.row.last_name}}
            </template>
          </el-table-column>
          <el-table-column label="Action" :min-width="150" align="center">
            <template v-slot="scope">
              <el-button v-if="last_unit" type="danger" @click="demoteUser(scope.row.id)" icon="">Demote to {{last_unit.name}}</el-button>
              <el-button v-if="next_unit" type="primary" @click="promoteUser(scope.row.id)" icon="">Promote to {{next_unit.name}}</el-button>
            </template>
          </el-table-column>
        </el-table>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="closeModal('users')" icon="">Cancel</el-button>
        </span>
      </div>
      <div class="text-center" v-else>
        <el-button v-if="delete_unit" type="danger" @click="deleteUnit(delete_unit.id)" icon="">Delete {{delete_unit.name}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Dialog, Select, Option, Input, Button, Checkbox, Table, TableColumn, Switch, Tooltip } from 'element-ui';
import UnitsAPIService from "src/servicehandlers/UnitsAPIService";
import UserAPIService from "../../../../servicehandlers/UsersAPIService";
import MasterPrintDialog from "../../Components/MasterPrintDialog.vue";

const unitsAPIService = new UnitsAPIService();
const userAPIService = new UserAPIService()

export default {
  name: "UnitsAdmin",
  components: {
    //MasterPrintDialog,
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Option.name]: Option,
    [Input.name]: Input,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Switch.name]: Switch,
    [Tooltip.name]: Tooltip,
  },
  data() {
    return {
      spinning: false,
      is_unit_selected: false,
      selected_unit_id: null,
      selected_unit: null,
      selected_units: [],
      new_unit: {},
      units: [],
      filteredUnits: [],
      reorder_units: false,
      error_txt: null,
      program_name: null,
      success_txt: null,
      last_order: null,
      modals: {
        error: false,
        success: false,
        person: false,
        create: false,
        users: false,
      },
      search: '',
      users: [],
      last_unit: null,
      delete_unit: null,
      next_unit: null,
    }
  },
  mounted() {
    this.getUnits();
  },
  methods: {
    toggleSelected() {
      this.is_unit_selected = true;
      this.selected_units = []
      for (const unit of this.units) {
        if (this.selected_unit_id && this.selected_unit_id === unit.id) {
          this.selected_unit = unit;
          this.selected_units.push(unit)
          return;
        }
      }
    },
    onEnd() {
      // go through all the order numbers, align with the indexes
      const promises = [];
      for (let i = 0; i < this.units.length; i += 1) {
        const id = this.units[i].id;
        this.units[i].order = i + 1;
        promises.push(unitsAPIService.updateUnit(id, { order: i + 1 }, this.$router));
      }
      return Promise.all(promises)
      // now start updating both the old index and the new to the new values
      .then(() => {
        // const self = this;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Error',
          text: 'Error updating unit! ' + error,
          timer: 1000,
          confirmButtonClass: 'btn btn-danger btn-fill',
          showConfirmButton: false
        });
      });
    },
    getUnits() {
      return unitsAPIService.getUnits(this.$router)
      .then((response) => {
        this.units = response;
        for (const unit of this.units) {
          unit.text = unit.name;
          unit.value = unit.id;
          unit.order = parseFloat(unit.order)
          this.last_order = unit.order;
          unit.freestyle_breaks = !unit.assigned_breaks
        }
        this.filteredUnits = this.units
        this.selected_units = this.filteredUnits
      })
      .catch((error) => {
        this.error_txt = 'Error getting Units: ' + error;
        this.openModal('error');
      });
    },
    getUsers(id){
      this.users = []
      return userAPIService.getUserList(this.$router)
        .then((response) => {
          for (let user of response){
            if (user.unit_id === id){
              this.users.push(user)
            }
          }
        })
    },
    updateUnit(unit) {
      return unitsAPIService.updateUnit(unit.id, unit, this.$router)
      .then(() => {
        const self = this;
        /*this.success_txt = 'Unit update successful';
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs*/
      })
      .catch((error) => {
        this.error_txt = 'Error updating Unit: ' + error;
        this.openModal('error');
      });
    },
    async handleClose(done) {
      try {
        await this.$confirm('Are you sure you want to close this dialog?');
        done();
      } catch (e) {}
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      if (name === 'create') {
        this.new_unit = {};
      }
      this.modals[name] = false;
    },
    async deleteUnit(id) {
      await this.getUsers(id)
      if (this.users.length > 0){
        for (let i = 0; i < this.units.length; i++){
          if (this.units[i].id === id){
            if (i >= 1){
              this.last_unit = this.units[i - 1]
            } else {
              this.last_unit = null
            }
            this.delete_unit = this.units[i]
            if (i <= this.units.length - 1){
              this.next_unit = this.units[i + 1]
            } else {
              this.next_unit = null
            }
            break
          }
        }
        this.openModal('users')
      } else {
        return unitsAPIService.deleteUnit(id, this.$router)
        .then(() => {
          this.getUnits();
          this.updateOrder()
          this.selected_unit = {};
          this.is_unit_selected = false;
          const self = this;
          this.success_txt = 'Delete Unit successful';
          this.openModal('success');
          setTimeout(function () {
            self.modals['success'] = false; // Use your variable name
          }, 1500); // Hide after 5 secs
        })
        .catch((error) => {
          this.error_txt = 'Error deleting unit: ' + error;
          this.openModal('error');
        });
      }
    },
    createUnit(modalName) {
      if (!this.new_unit.name) {
        const self = this;
        this.error_txt = 'Missing unit name';
        this.openModal('error');
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
        return;
      }
      let order;
      if (this.last_order) {
        order = this.last_order;
        order += 1;
        this.last_order = order;
      } else {
        order = 1;
        this.last_order = 1;
      }
      if (!this.new_unit.freestyle_breaks) {
        this.new_unit.freestyle_breaks = false;
      }
      if (!this.new_unit.assigned_breaks) {
        this.new_unit.assigned_breaks = false;
      }
      if (!this.new_unit.show_poomsae) {
        this.new_unit.show_poomsae = false;
      }
      this.new_unit.order = order;
      this.new_unit.freestyle_breaks = !this.new_unit.assigned_breaks
      return unitsAPIService.addUnit(this.new_unit, this.$router)
      .then(() => {
        this.selected_unit = {};
        this.is_unit_selected = false;
        this.new_unit = {};
        this.getUnits();
        this.closeModal(modalName);
        const self = this;
        this.success_txt = 'Add Unit successful';
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
      .catch((error) => {
        this.error_txt = 'Error unit program: ' + error;
        this.openModal('error');
      });
    },
    moveUp(index) {
      if (index > 0) {
        const temp = this.units[index];
        if (temp.is_sub_unit){
          let subUnits = this.units.filter(data => data.is_sub_unit && (data.head_unit_id === temp.head_unit_id))
          let unitIndex = subUnits.indexOf(temp)
          if (unitIndex > 0){
            this.units.splice(index, 1);
            this.units.splice(index - 1, 0, temp);
          }
        } else {
          let found = false
          for (let unit of this.units){
            if (unit.head_unit_id === temp.id){
              found = true
              break
            }
          }
          if (found){
            let subCount = this.units.filter(data => data.is_sub_unit && (data.head_unit_id === temp.id)).length
            let prev = this.units[index - 1]
            this.units.splice(index - 1, 1)
            this.units.splice(index + subCount, 0, prev)
          } else {
            let prev = this.units[index - 1]
            if (prev.is_sub_unit){
              let headUnit = this.units.filter(data => data.id === prev.head_unit_id)[0]
              let newIndex = this.units.indexOf(headUnit)
              this.units.splice(index, 1)
              this.units.splice(newIndex, 0, temp)
            } else {
              this.units.splice(index, 1);
              this.units.splice(index - 1, 0, temp);
            }
          }
        }
        this.updateOrder()
      }
    },
    moveDown(index) {
      if (index < this.units.length - 1) {
        const temp = this.units[index];
        if (temp.is_sub_unit){
          let subUnits = this.units.filter(data => data.is_sub_unit && (data.head_unit_id === temp.head_unit_id))
          let unitIndex = subUnits.indexOf(temp)
          if (unitIndex < subUnits.length - 1){
            this.units.splice(index, 1);
            this.units.splice(index + 1, 0, temp);
          }
        } else {
          let found = false
          for (let unit of this.units){
            if (unit.head_unit_id === temp.id){
              found = true
              break
            }
          }
          if (found){
            let subCount = this.units.filter(data => data.is_sub_unit && (data.head_unit_id === temp.id)).length
            let next = this.units[index + subCount + 1]
            this.units.splice(index + subCount + 1, 1)
            this.units.splice(index - 1, 0, next)
          } else {
            let next = this.units[index + 1]
            let found = false
            for (let unit of this.units){
              if (unit.head_unit_id === next.id){
                found = true
                break
              }
            }
            if (found){
              let subUnits = this.units.filter(data => data.is_sub_unit && (data.head_unit_id === next.id))
              let newIndex =  this.units.indexOf(subUnits[subUnits.length - 1])
              this.units.splice(index, 1)
              this.units.splice(newIndex, 0, temp)
            } else {
              this.units.splice(index, 1);
              this.units.splice(index + 1, 0, temp);
            }
          }
        }
        this.updateOrder()
      }
    },
    updateOrder(){
      let count = 0
      let subCount = 1
      for (let i = 0; i < this.units.length; i++){
        let unit = this.units[i]
        if (unit.is_sub_unit){
          unit.order = count + (subCount * .1)
          subCount++
        } else {
          count++
          subCount = 1
          unit.order = count
        }
        this.updateUnit(this.units[i])
      }
    },
    handleSortChange({ column, prop, order}) {
      if (order === "ascending") {
        this.filteredUnits.sort((a, b) => (a[prop] > b[prop] ? 1 : -1));
      } else if (order === "descending") {
        this.filteredUnits.sort((a, b) => (a[prop] < b[prop] ? 1 : -1));
      }
    },
    filterUnits(){
      try {
        this.filteredUnits = this.units.filter(
          (data) =>
            !this.search ||
            data.name.toLowerCase().includes(this.search.toLowerCase())
        )
      } catch (e) {
        this.error_txt = 'One or more names missing'
        this.openModal('error')
      }
    },
    toggleBreaksAssign(unit){
      unit.assigned_breaks = !unit.freestyle_breaks
      this.updateUnit(unit)
    },
    tableRowClassName(value) {
      if (value) {
        return 'subUnit';
      }
      return '';
    },
  }
}
</script>

<style>
.subUnit{
  margin-left: 50px;
}
</style>
