import CommonAPIService from './CommonHandler';

export class UnitsAPIService {
  constructor() {
    this.search = '';
    this.CommonAPIService = new CommonAPIService();
  }

  getUnits(router) {
    const url = '/api/units';
    return this.CommonAPIService.getCall(url, '', router);
  }

  getUnitsToPoomsae(router) {
    const url = '/api/units/poomsae';
    return this.CommonAPIService.getCall(url, '', router);
  }

  getUnitsToTechniques(router) {
    const url = '/api/units/techniques';
    return this.CommonAPIService.getCall(url, '', router);
  }

  addUnit(unit, router) {
    const url = '/api/units';
    return this.CommonAPIService.postCall(url, unit, router);
  }

  updateUnit(unitId, unit, router) {
    const url = `/api/units/${unitId}`;
    return this.CommonAPIService.putCall(url, unit, router);
  }

  deleteUnit(unitId, router) {
    const url = `/api/units/${unitId}`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }
}

export default UnitsAPIService;
