<template>
  <div>
    <el-popover placement="right" trigger="hover" :close-delay="0">
      <img :src="imgURL" alt="Example Picture" :width="imgWdith">
      <el-button v-if="disabled" disabled slot="reference" style="margin: 6px" type="primary" @click="startProcess('print', type, givenData)" icon="">{{typeString}}</el-button>
      <el-button v-else slot="reference" style="margin: 6px" type="primary" @click="startProcess('print', type, givenData)" icon="">{{typeString}}</el-button>
    </el-popover>
    <el-dialog
      center
      :width="dialogWidth"
      :title="'Print ' + typeString"
      :visible.sync="modals.print"
      :modalAppendToBody="false">
      <div v-if="rollTypes.includes(type)">
        <card>
          <div class="row">
            <div class="col-sm-12 text-center">
              Print By
            </div>
            <div class="col-sm-12">
              <el-select filterable clearable v-model="printType" placeholder="Print By" style="width: 100%"
                         @change="filterData">
                <el-option v-for="item in printByTypes.filter(data => data.type.includes(type.split('-')[1]))"
                           :key="item.id" :value="item.id" :label="item.name">
                </el-option>
              </el-select>
            </div>
          </div>
        </card>
        <div class="row" v-if="printType">
          <div class="col-sm-12 text-center">
            {{printByTypes[printType].name}}
          </div>
        </div>
        <el-table stripe :data="filteredData" style="width: 100%" height="40vh" v-if="filteredData.length > 0">
          <el-table-column :min-width="150" align="center">
            <template slot="header">
              <el-checkbox :checked="isChecked()" id="select_all" @change="clickSelect"></el-checkbox>
              Select
            </template>
            <template v-slot="scope">
              <el-checkbox type="checkbox" :value="checkSelected(scope.row.id)" @change="selectID(scope.row.id)"></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column label="Name" :min-width="150" align="center">
            <template v-slot="scope">
              {{ scope.row && scope.row.name ? scope.row.name : 'No Name Available' }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-else-if="eventTypes.includes(type)">
        <card>
          <div class="row">
            <div class="col-sm-12 text-center">
              Filters
            </div>
            <div class="col-sm-4">
              <el-select filterable clearable v-model="programFilter" placeholder="Select Filter Program" style="width: 100%"
                         @change="filterData">
                <el-option v-for="item in allPrograms"
                           :key="item.id" :value="item.id" :label="item.name">
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-4">
              <el-select filterable clearable v-model="rankFilter" placeholder="Select Filter Rank" style="width: 100%"
                         @change="filterData">
                <el-option v-for="item in allRanks"
                           :key="item.id" :value="item.id" :label="item.name">
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-4">
              <el-input v-model="search" @keyup.native="filterData" placeholder="Search by name"></el-input>
            </div>
          </div>
        </card>
        <el-table stripe :data="filteredData" style="width: 100%" height="40vh">
          <el-table-column :min-width="150" align="center">
            <template slot="header">
              <el-checkbox :checked="isChecked()" id="select_all" @change="clickSelect"></el-checkbox>
              Select
            </template>
            <template v-slot="scope">
              <el-checkbox type="checkbox" :value="checkSelected(scope.row.id)" @change="selectID(scope.row.id)"></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column label="Name" :min-width="150" align="center">
            <template v-slot="scope">
              {{scope.row.user.first_name}} {{scope.row.user.last_name}}
            </template>
          </el-table-column>
          <el-table-column label="Program" :min-width="150" align="center">
            <template v-slot="scope">
              {{scope.row.program.name}}
            </template>
          </el-table-column>
          <el-table-column label="Current Rank" :min-width="150" align="center">
            <template v-slot="scope">
              {{scope.row.rank.name}}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-else-if="type === 'curriculum'" class="text-center">
        <el-button type="primary" @click="preCurPrint(true)" icon="">Print Current Curriculum</el-button>
        <br><br>
        <el-button type="primary" @click="preCurPrint(false)" icon="">Print Next Curriculum</el-button>
        <br><br>
        <el-button v-if="stepSparring && stepSparring.pdf" type="primary" @click="downloadPDF(stepSparring.pdf, 'step_sparring', stepSparring.id)" icon="">Download Current Step Sparring</el-button>
      </div>
      <div v-else-if="type === 'rank-curriculum'">
        <card>
          <div class="row">
            <div class="col-sm-6 text-center">
              Limit to
              <el-select filterable clearable v-model="rankTypeFilter" placeholder="Rank Level" style="width: 100%"
                         @change="filterData">
                <el-option v-for="item in rank_types"
                           :key="item.id" :value="item.id" :label="item.name">
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-6 text-center">
              <el-button v-if="selectedData.length === filteredData.length" type="primary" @click="clickSelect" icon="">Deselect All From Each Program</el-button>
              <el-button v-else type="primary" @click="clickSelect" icon="">Select All From Each Program</el-button>
            </div>
          </div>
        </card>
        <el-tabs type="border-card" v-model="selectedProgram">
          <el-tab-pane v-for="program of allPrograms" v-bind:key="program.id" :label="program.name">
            <el-table stripe :data="filteredData.filter(data => data.program_id === program.id)" style="width: 100%" height="40vh" v-if="filteredData.length > 0">
              <el-table-column :min-width="150" align="center">
                <template slot="header">
                  <el-checkbox :checked="isCheckedProgram(program.id)" id="select_all" @change="selectFromCurrentProgram(filteredData.filter(data => data.program_id === program.id), program.id)"></el-checkbox>
                  Select
                </template>
                <template v-slot="scope">
                  <el-checkbox type="checkbox" :value="checkSelected(scope.row.id)" @change="selectID(scope.row.id)"></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column label="Name" :min-width="150" align="center">
                <template v-slot="scope">
                  {{ scope.row && scope.row.name ? scope.row.name : 'No Name Available' }}
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>

      <span slot="footer" class="dialog-footer">
        <div class="row">
          <div class="col-sm-2 text-center" v-if="type === 'event-curriculum'">
            <el-button type="success" @click="preCurPrint(true)" v-if="selectedData.length > 0" icon="">Print Current Curriculum</el-button>
            <br><br>
            <el-button type="success" @click="preCurPrint(false)" v-if="selectedData.length > 0" icon="">Print Next Curriculum</el-button>
          </div>
          <div class="col-sm-2 text-center" v-else-if="type === 'rank-curriculum'">
            <el-button type="success" @click="preRankCurPrint" v-if="selectedData.length > 0" icon="">Print Curriculum</el-button>
          </div>
          <div class="col-sm-2" v-else>
            <el-button type="success" @click="print" icon="" v-if="selectedData.length > 0">Print {{typeString}}</el-button>
          </div>
          <div class="col-sm-3 align-content-center">
            <el-input v-if="type === 'black-belt-certs'" v-model="theme" placeholder="Theme"></el-input>
          </div>
          <div class="col-sm-5"></div>
          <div class="col-sm-2 text-right align-content-center">
            <el-button type="danger" @click="closePrintModal('print')" icon="">Close</el-button>
          </div>
        </div>
    </span>
    </el-dialog>
    <el-dialog
      center
      title="Organize By"
      :visible.sync="modals.rankCur"
      :modalAppendToBody="false">
      <div class="text-center">
        <el-switch style="height: 50px" v-model="printByTapes" active-text="Tapes" inactive-text="Ranks"></el-switch>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="print" icon="">Print</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      :before-close="handleClose"
      title="Error"
      :visible.sync="modals.error"
      :modalAppendToBody="false">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="closeModal('error')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      :before-close="handleClose"
      title="Success"
      :visible.sync="modals.success"
      :modalAppendToBody="false">
      <div class="text-center">
        <span>{{ success_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="closeModal('success')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      :visible.sync="loading"
      :modalAppendToBody="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="200px"
      height="200px"
      top="calc(50vh - 100px)">
      <div class="text-center">
        <el-progress type="circle" v-if="precentage >= 100" :percentage="precentage" status="success"></el-progress>
        <el-progress type="circle" v-else :percentage="precentage"></el-progress>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  Button,
  Checkbox,
  Dialog,
  Input,
  Option,
  Popover,
  Progress,
  Select,
  Switch,
  Table,
  TableColumn,
  TabPane,
  Tabs
} from 'element-ui';
import RanksAPIService from "../../../servicehandlers/RanksAPIService";
import ProgramsAPIService from "../../../servicehandlers/ProgramsAPIService";
import PoomsaeAPIService from "../../../servicehandlers/PoomsaeAPIService";
import KicksAPIService from "../../../servicehandlers/KicksAPIService";
import SelfDefenseTechniquesAPIService from "../../../servicehandlers/SelfDefenseTechniquesAPIService";
import KickCombosAPIService from "../../../servicehandlers/KickCombosAPIService";
import TechniquesAPIService from "../../../servicehandlers/TechniquesAPIService";
import ModifiersAPIService from "../../../servicehandlers/ModifiersAPIService";
import PenaltiesAPIService from "../../../servicehandlers/PenaltiesAPIService";
import BoardsAPIService from "../../../servicehandlers/BoardsAPIService";
import BreakingTechniquesAPIService from "../../../servicehandlers/BreakingTechniquesAPIService";
import SupportOptionsAPIService from "../../../servicehandlers/SupportOptionsAPIService";
import DirectionsAPIService from "../../../servicehandlers/DirectionsAPIService";
import TestingTypesAPIService from "../../../servicehandlers/TestingTypesAPIService";
import RankTypesAPIService from "../../../servicehandlers/RankTypesAPIService";
import StepSparringAPIService from "../../../servicehandlers/StepSparringAPIService";
import OutlinesAPIService from "../../../servicehandlers/OutlinesAPIService";
import {printTestingSheets} from "./printing/printTestingSheets";
import {printBBTestingSheets} from "./printing/printBBTestingSheets";
import {printCurriculum} from "./printing/printCurriculum";
import {printTapes} from "./printing/printTapes";
import {downloadPDF} from "./printing/downloadPDF";
import {printFoundationCerts} from "./printing/printFoundationCerts";
import {printScores} from "./printing/printScores";
import {printOutlines} from "./printing/printOutlines";
import {printRollSheets} from "./printing/printRollSheets";
import {printBBTRollSheets} from "./printing/printBBTRollSheets";
import {printCallSheets} from "./printing/printCallSheets";
import {printBoardCount} from "./printing/printBoardCount";
import {setTechniqueNames} from "./printing/setTechniqueNames";
import {calculateScoresForOutline} from "./printing/calculateScoresForOutline";

const ranksAPIService = new RanksAPIService();
const programAPIService = new ProgramsAPIService()
const poomsaeAPIService = new PoomsaeAPIService()
const kicksAPIService = new KicksAPIService()
const selfDefenseTechniquesAPIService = new SelfDefenseTechniquesAPIService()
const kickCombosAPIService = new KickCombosAPIService()
const techniqueAPIService = new TechniquesAPIService()
const modifiersAPIService = new ModifiersAPIService();
const penaltiesAPIService = new PenaltiesAPIService();
const boardsAPIService = new BoardsAPIService();
const breakingTechniquesAPIService = new BreakingTechniquesAPIService();
const supportOptionsAPIService = new SupportOptionsAPIService();
const directionsAPIService = new DirectionsAPIService();
const testingTypesAPIService = new TestingTypesAPIService()
const rankTypesAPIService = new RankTypesAPIService()
const stepSparringAPIService = new StepSparringAPIService()
const outlineAPIService = new OutlinesAPIService()

export default {
  name: "Outline",
  components: {
    [Dialog.name]: Dialog,
    [Option.name]: Option,
    [Select.name]: Select,
    [Input.name]: Input,
    [Checkbox.name]: Checkbox,
    [Button.name]: Button,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Switch.name]: Switch,
    [Progress.name]: Progress,
    [Popover.name]: Popover,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
  },
  props: {
    type: String,
    givenData: Array,
    printName: String,
    disabled: Boolean,
  },
  data() {
    return {
      loading: false,
      modals: {
        error: false,
        success: false,
        print: false,
        rankCur: false,
      },
      error_txt: '',
      success_txt: '',
      typeString: '',
      filteredData: [],
      programFilter: null,
      rankFilter: null,
      allRanks: [],
      allPrograms: [],
      addedPrograms: [],
      search: '',
      selectAll: false,
      selectedData: [],
      categories: [
        {
          id: 'spirit',
          name: 'Martial Arts Spirit',
          items: [
            {
              name: 'Presentation'
            }
          ],
        },
        {
          id: 'kicks',
          name: 'Kicking',
          items: [],
          tape: 'Blue'
        },
        {
          id: 'kick_combos',
          name: 'Kicking Combos',
          items: [],
          tape: 'Red'
        },
        {
          id: 'poomsae',
          name: 'Poomsae',
          items: [],
          tape: 'Black'
        },
        {
          id: 'techniques',
          name: 'Techniques',
          items: [],
          tape: 'Green'
        },
        {
          id: 'self_defense',
          name: 'Self Defence',
          items: [],
          tape: 'Brown'
        },
        {
          id: 'breaking',
          name: 'Breaking',
          items: [],
          tape: 'Breaking'
        },
      ],
      eventTypes: ['event-test-sheets', 'foundation-certs', 'color-belt-certs', 'prelim-certs', 'black-belt-certs', 'event-scores', 'event-outlines', 'event-curriculum', 'bbt-test-sheets'],
      rollTypes: ['event-roll-sheet', 'event-call-sheet', 'bbt-roll-sheet', 'bbt-call-sheet'],
      theme: '',
      checkbox_modifiers: [],
      penalties: [],
      dropdown_boards: [],
      dropdown_techniques: [],
      dropdown_support_options: [],
      allDirections: [],
      printByGroup: false,
      printByTypes: [
        {
          id: 0,
          name: 'Program',
          type: 'roll',
        },
        {
          id: 1,
          name: 'Rank',
          type: 'rollcall',
        },
        {
          id: 2,
          name: 'Groups',
          type: 'rollcall',
        },
        {
          id: 3,
          name: 'Rank Level',
          type: 'roll',
        },
        {
          id: 4,
          name: 'Tests',
          type: 'rollcall',
        },
      ],
      printType: null,
      dialogWidth: '50%',
      testing_types: [],
      rank_types: [],
      precentage: 0,
      imgURL: '',
      imgWdith: '',
      printCurrent: null,
      stepSparring: null,
      selectedProgram: null,
      rankTypeFilter: null,
      printByTapes: false,
    }
  },
  computed: {
    isChecked(){
      return () => {
        return this.selectAll
      }
    }
  },
  mounted(){
    this.getTypeString()
    //console.clear()
  },
  beforeDestroy(){
    clearInterval(this.imgInterval)
  },
  methods: {
    //          Get Functions
    getTypeString(){
      switch (this.type){
        case 'event-test-sheets':
          this.typeString = 'Testing Sheets'
          this.imgURL = '/static/img/testing-sheet-example.png'
          this.imgWdith = '150px'
          let VO = this
          this.imgInterval = setInterval(function (){
            if (VO.imgURL === '/static/img/testing-sheet-example.png'){
              VO.imgURL = '/static/img/foundations-testing-sheet-example.png'
            } else {
              VO.imgURL = '/static/img/testing-sheet-example.png'
            }
          }, 2000)
          break
        case 'foundation-certs':
          this.typeString = 'Foundations Certificates'
          this.imgURL = '/static/img/foundations-cert-example.jpg'
          this.imgWdith = '210px'
          break
        case 'color-belt-certs':
          this.typeString = 'Color Belt Certificates'
          this.imgURL = '/static/img/color-belt-cert-example.jpg'
          this.imgWdith = '210px'
          break
        case 'prelim-certs':
          this.typeString = 'Prelim Certificates'
          this.imgURL = '/static/img/prelim-cert-example.jpg'
          this.imgWdith = '210px'
          break
        case 'black-belt-certs':
          this.typeString = 'Black Belt Certificates'
          this.imgURL = '/static/img/black-belt-cert-example.jpg'
          this.imgWdith = '210px'
          break
        case 'event-scores':
          this.typeString = 'Scores'
          this.imgURL = '/static/img/score-sheet-example.jpg'
          this.imgWdith = '210px'
          break
        case 'single-score':
          this.typeString = 'Print Score'
          this.imgURL = '/static/img/score-sheet-example.jpg'
          this.imgWdith = '210px'
          break
        case 'event-outlines':
          this.typeString = 'Outlines'
          this.imgURL = '/static/img/outline-sheet-example.jpg'
          this.imgWdith = '210px'
          break
        case 'single-outline':
          this.typeString = 'Print Outline'
          this.imgURL = '/static/img/outline-sheet-example.jpg'
          this.imgWdith = '210px'
          break
        case 'event-roll-sheet':
          this.typeString = 'Roll Sheets'
          this.imgURL = '/static/img/roll-sheet-example.jpg'
          this.imgWdith = '150px'
          break
        case 'bbt-call-sheet':
          this.typeString = 'BBT Call Sheets'
        case 'event-call-sheet':
          if (!this.typeString) this.typeString = 'Call Sheets'
          this.imgURL = '/static/img/call-sheet-example.jpg'
          this.imgWdith = '150px'
          break
        case 'test-sheet':
          this.typeString = 'Print Test Sheet'
          this.imgURL = '/static/img/testing-sheet-example.png'
          this.imgWdith = '150px'
          break
        case 'curriculum':
        case 'event-curriculum':
          if (this.type === "curriculum"){
            this.typeString = 'Print Curriculum'
          } else {
            this.typeString = 'Curriculum'
          }
          this.imgURL = '/static/img/curriculum-example.jpg'
          this.imgWdith = '150px'
          break
        case 'bbt-test-sheets':
          this.typeString = 'BBT Testing Sheets'
          this.imgURL = '/static/img/bbt-testing-example.jpg'
          this.imgWdith = '150px'
          break
        case 'bbt-roll-sheet':
          this.typeString = 'BBT Roll Sheets'
          //this.imgURL = '/static/img/roll-sheet-example.jpg'
          this.imgWdith = '150px'
          break
        case 'bbt-curriculum':
          this.typeString = 'BBT Curriculum'
          //this.imgURL = '/static/img/curriculum-example.jpg'
          this.imgWdith = '150px'
          break
        case 'rank-curriculum':
          this.typeString = 'Print Rank Curriculum'
          this.imgURL = '/static/img/curriculum-example.jpg'
          this.imgWdith = '150px'
          break
        case 'event-board-count':
          this.typeString = 'Board Count'
          //this.imgURL = '/static/img/curriculum-example.jpg'
          this.imgWdith = '150px'
          break
        default:
          this.typeString = 'Unknown'
          break
      }
    },
    getRanks() {
      return ranksAPIService.getRanks(this.$router)
        .then((ranks) => {
          this.allRanks = ranks
        })
        .catch((error) => {
          this.error_txt = 'Error getting ranks: ' + error;
          this.openModal('error');
        });
    },
    getPrograms(){
      return programAPIService.getPrograms(this.$router)
        .then((response) => {
          this.allPrograms = response
        }).catch((error) => {
          this.error_txt = 'Error getting programs: ' + error;
          this.openModal('error');
        });
    },
    getPoomsaeByRankId(id){
      return poomsaeAPIService.getPoomsae(this.$router)
        .then((poomsae_resp) => {

          poomsae_resp.sort((a, b) => {
            if (a.order && b.order) {
              return a.order - b.order;
            }
            return 0;
          });

          for (const category of this.categories) {
            if (category.id === 'poomsae') {
              category.dropdown_list = [];
              for (const poomsae of poomsae_resp) {
                poomsae.value = poomsae.id;
                poomsae.text = poomsae.description;
                poomsae.name = poomsae.description;
                category.dropdown_list.push(poomsae);
              }
              break;
            }
          }
          return poomsaeAPIService.getPoomsaeByRank(id, this.$router)
        })
        .then((response) => {
          // now sort through the items
          for (const category of this.categories) {
            if (category.id === 'poomsae') {
              category.items = [];
              for (const item of response) {
                for (const poomsae of category.dropdown_list){
                  if (poomsae.id === item.poomsae_id){
                    if (item.tested){
                      item.name = poomsae.name
                      item.id = poomsae.id
                      category.items.push(item);
                    }
                  }
                }
              }
              break;
            }
          }
        })
        .catch((error) => {
          this.error_txt = 'Error getting poomsae: ' + error;
          this.openModal('error');
        });
    },
    getKicksByRankId(id){
      return kicksAPIService.getKick(this.$router)
        .then((kicks_resp) => {

          kicks_resp.sort((a, b) => {
            if (a.order && b.order) {
              return a.order - b.order;
            }
            return 0;
          });

          for (const category of this.categories) {
            if (category.id === 'kicks') {
              category.dropdown_list = [];
              for (const kick of kicks_resp) {
                kick.value = kick.id;
                kick.text = kick.description;
                category.dropdown_list.push(kick);
              }
              break;
            }
          }
          return kicksAPIService.getKickByRank(id, this.$router)
        })
        .then((response) => {
          // now sort through the items
          for (const category of this.categories) {
            if (category.id === 'kicks') {
              category.items = [];
              for (const item of response) {
                for (const kick of category.dropdown_list){
                  if (kick.id === item.kick_id){
                    if (item.tested){
                      item.name = kick.name
                      item.id = kick.id
                      item.description = kick.description
                      category.items.push(item);
                    }
                  }
                }
              }
              break;
            }
          }
        })
        .catch((error) => {
          this.error_txt = 'Error getting kicks: ' + error;
          this.openModal('error');
        });
    },
    getSelfDefByRankId(id){
      return selfDefenseTechniquesAPIService.getSelfDefenseTechniques(this.$router)
        .then((response) => {

          response.sort((a, b) => {
            if (a.order && b.order) {
              return a.order - b.order;
            }
            return 0;
          });

          for (const category of this.categories) {
            if (category.id === 'self_defense') {
              category.dropdown_list = [];
              for (const item of response) {
                item.value = item.id;
                item.text = item.description;
                item.name = item.description;
                category.dropdown_list.push(item);
              }
              break;
            }
          }
          return selfDefenseTechniquesAPIService.getSelfDefenseTechniquesByRank(id, this.$router)
        })
        .then((response) => {
          // now sort through the items
          for (const category of this.categories) {
            if (category.id === 'self_defense') {
              category.items = [];
              for (const item of response) {
                for (const self_def of category.dropdown_list){
                  if (self_def.id === item.self_defense_techniques_id){
                    if (item.tested){
                      item.name = self_def.name
                      item.id = self_def.id
                      category.items.push(item);
                    }
                  }
                }
              }
              break;
            }
          }
        })
        .catch((error) => {
          this.error_txt = 'Error getting Self Defense Items: ' + error;
          this.openModal('error');
        });
    },
    getKickCombosByRankId(id){
      return kickCombosAPIService.getKickCombos(this.$router)
        .then((response) => {
          response.sort((a, b) => {
            if (a.order && b.order) {
              return a.order - b.order;
            }
            return 0;
          });
          for (const category of this.categories) {
            if (category.id === 'kick_combos') {
              category.dropdown_list = [];
              for (const item of response) {
                item.value = item.id;
                item.text = item.description;
                item.name = item.description;
                category.dropdown_list.push(item);
              }
              break;
            }
          }
          return kickCombosAPIService.getKickCombosByRank(id, this.$router)
        })
        .then((response) => {
          // now sort through the items
          for (const category of this.categories) {
            if (category.id === 'kick_combos') {
              category.items = [];
              for (let item of response){
                for (const kick_combo of category.dropdown_list){
                  if (kick_combo.id === item.kick_combo_id){
                    if (item.tested){
                      item.name = kick_combo.name
                      item.id = kick_combo.id
                      item.description = kick_combo.description
                      category.items.push(item);
                    }
                  }
                }
              }
              break;
            }
          }
        })
        .catch((error) => {
          this.error_txt = 'Error getting Kick Combos: ' + error;
          this.openModal('error');
        });
    },
    getTechsByRankId(id){
      return techniqueAPIService.getTechnique(this.$router)
        .then((response) => {
          for (const category of this.categories) {
            if (category.id === 'techniques') {
              category.dropdown_list = [];
              for (const item of response) {
                item.value = item.id;
                item.text = item.description;
                item.name = item.description;
                category.dropdown_list.push(item);
              }
              break;
            }
          }
          return techniqueAPIService.getTechniqueByRank(id, this.$router)
        })
        .then((response) => {
          // now sort through the items
          for (const category of this.categories) {
            if (category.id === 'techniques') {
              category.items = [];
              category.items.push({name: 'Step Sparring: ' + this.stepSparring.name})
              for (const item of response) {
                for (const tech of category.dropdown_list){
                  if (tech.id === item.technique_id){
                    if (item.tested){
                      item.name = tech.name
                      item.id = tech.id
                      category.items.push(item);
                    }
                  }
                }
              }
              break;
            }
          }
        })
        .catch((error) => {
          this.error_txt = 'Error getting techniques: ' + error;
          this.openModal('error');
        });
    },
    async getDefaultOutlineBreaksByRankId(id){
      await this.getSupportOptions()
      return outlineAPIService.getDefaultOutlineDetailsByRankId(id, this.$router)
        .then(response => {
          if (response){
            for (const category of this.categories){
              if (category.id === 'breaking'){
                category.items = []
                this.setTechniqueNames(response)
                for (let tech of response.breaking_techniques_to_outlines){
                  category.items.push(tech)
                }
              }
            }
          }
        })
        .catch(e => {
          this.error_txt = 'Error getting default outline for rank: ' + e
          this.openModal('error')
        })
    },
    clearCategories(){
      this.categories = [
        {
          id: 'spirit',
          name: 'Martial Arts Spirit',
          items: [
            {
              name: 'Presentation'
            }
          ],
        },
        {
          id: 'kicks',
          name: 'Kicking',
          items: [],
          tape: 'Blue'
        },
        {
          id: 'kick_combos',
          name: 'Kicking Combos',
          items: [],
          tape: 'Red'
        },
        {
          id: 'poomsae',
          name: 'Poomsae',
          items: [],
          tape: 'Black'
        },
        {
          id: 'techniques',
          name: 'Techniques',
          items: [],
          tape: 'Green'
        },
        {
          id: 'self_defense',
          name: 'Self Defence',
          items: [],
          tape: 'Brown'
        },
        {
          id: 'breaking',
          name: 'Breaking',
          items: [],
          tape: 'Breaking'
        },
      ]
    },
    getModifiers() {
      return modifiersAPIService.getModifiers(this.$router)
        .then((modifiers) => {
          this.checkbox_modifiers = []
          for (const modifier of modifiers) {
            modifier.text = modifier.description;
            modifier.value = modifier.id;
            this.checkbox_modifiers.push(modifier);
          }
        })
        .catch((error) => {
          this.error_txt = 'Error getting modifiers for dropdown: ' + error;
          this.openModal('error');
        });
    },
    getPenalties() {
      return penaltiesAPIService.getPenalties(this.$router)
        .then((penalties) => {
          this.penalties = penalties;
        })
        .catch((error) => {
          this.error_txt = 'Error getting penalties: ' + error;
          this.openModal('error');
        });
    },
    getBoards() {
      return boardsAPIService.getBoards(this.$router)
        .then((boards) => {
          for (let i = 0; i < boards.length; i += 1) {
            boards[i].text = boards[i].name;
            boards[i].value = boards[i].id;
          }
          const tmpBoard = {
            value: null,
            text: 'N/A',
            id: null,
            name: 'N/A'
          };
          boards.unshift(tmpBoard);
          this.dropdown_boards = boards;
        })
        .catch((error) => {
          this.error_txt = 'Error getting boards for dropdown: ' + error;
          this.openModal('error');
        });
    },
    getTechniques() {
      return breakingTechniquesAPIService.getBreakingTechniques(this.$router)
        .then((techniques) => {
          for (const technique of techniques) {
            technique.text = technique.name;
            technique.value = technique.id;
          }
          const tmpTechnique = {
            text: '-------',
            value: null,
            id: null,
            name: '-------',
          };
          techniques.unshift(tmpTechnique);
          this.dropdown_techniques = techniques;
        })
        .catch((error) => {
          this.error_txt = 'Error getting techniques for dropdown: ' + error;
          this.openModal('error');
        });
    },
    getSupportOptions() {
      return supportOptionsAPIService.getSupportOptions(this.$router)
        .then((modifiers) => {
          this.dropdown_support_options = []
          for (const modifier of modifiers) {
            modifier.text = modifier.description;
            modifier.value = modifier.id;
            this.dropdown_support_options.push(modifier);
          }
        })
        .catch((error) => {
          this.error_txt = 'Error getting modifiers for dropdown: ' + error;
          this.openModal('error');
        });
    },
    getAllDirections() {
      return directionsAPIService.getAllDirections(this.$router)
        .then((directions) => {
          for (const dir of directions) {
            dir.text = dir.name;
            dir.value = dir.id;
          }
          this.allDirections = directions;
        })
        .catch((error) => {
          this.error_txt = 'Error getting directions: ' + error;
          this.openModal('error');
        });
    },
    getTestingTypes(){
      return testingTypesAPIService.getTestingTypes(this.$router)
        .then((response) => {
          this.testing_types = response
        })
        .catch((e) => {
          this.error_txt = 'Error getting testing types' + e
          this.openModal('error')
        })
    },
    getRankTypes(){
      return rankTypesAPIService.getRankTypes(this.$router)
        .then((response) => {
          this.rank_types = response
        })
        .catch((e) => {
          this.error_txt = "Error getting rank types: " + e
          this.openModal('error')
        })
    },
    getStepSparring(){
      return stepSparringAPIService.getCurrentStepSparrings(this.$router)
        .then((response) => {
          this.stepSparring = response
        })
    },


    //          Page Functions
    async startProcess(name, type, data) {
      //console.clear()
      if (this.allRanks.length <= 0){
        this.getRanks()
      }
      if (this.allPrograms.length <= 0){
        this.getPrograms()
      }
      //console.log(type)
      //console.log(data)
      if (this.eventTypes.includes(type)){
        this.getStepSparring()
        this.filteredData = data
        this.dialogWidth = '90%'
        this.modals[name] = true;
        this.clickSelect()
      } else {
        switch (type){
          case 'bbt-call-sheet':
          case 'event-call-sheet':
          case 'event-roll-sheet':
            this.getTestingTypes()
            this.getRankTypes()
            this.clickSelect()
            this.modals[name] = true;
            break
            /*for (let order of data){
              if (!this.filteredData.some(e => e.id === order.rank.id)){
                let temp = {
                  id: order.rank.id,
                  name: order.rank.name,
                  order: order.rank.order
                }
                this.filteredData.push(temp)
              }
            }
            this.filteredData.sort((a, b) => a.order - b.order);
            this.modals[name] = true;
            break*/
          case 'test-sheet':
            this.printTestingSheets()
            break
          case 'curriculum':
            this.getStepSparring()
            for (let item of data){
              this.selectedData.push(item.id)
            }
            this.modals[name] = true;
            break
          case 'bbt-roll-sheet':
            this.print()
            break
          case 'rank-curriculum':
            this.getRankTypes()
            this.getStepSparring()
            this.filteredData = data
            this.modals[name] = true;
            break
          case 'single-outline':
            this.selectedData = []
            this.selectedData.push(data[0].id)
            this.print()
            break
          case 'single-score':
            this.selectedData = []
            this.selectedData.push(data[0].id)
            this.print()
            break
          case 'event-board-count':
            await this.getBoards()
            this.print()
            break
          default:
            this.error_txt = "Unknown Print Type: " + this.type
            this.openModal('error')
            return
        }
      }
    },
    async handleClose(done) {
      try {
        await this.$confirm('Are you sure you want to close this dialog?');
        done();
      } catch (e) {}
    },
    closePrintModal(name) {
      this.selectAll = false
      this.selectedData = [];
      this.modals[name] = false;
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
    },


    //          Data Functions
    filterData(){
      //console.log(this.printType)
      if (this.eventTypes.includes(this.type)){
        this.filteredData = this.givenData.filter(
          (data) =>
            !this.search ||
            (data.user.first_name.toLowerCase() + data.user.last_name.toLowerCase()).includes(this.search.toLowerCase())
        ).filter(
          (data) =>
            !this.programFilter ||
            (data.program.id === this.programFilter)
        ).filter(
          (data) =>
            !this.rankFilter ||
            (data.rank.id === this.rankFilter)
        )
      } else {
        switch (this.type){
          case 'bbt-roll-sheet':
          case 'event-call-sheet':
          case 'event-roll-sheet':
            this.filteredData = []
            for (let order of this.givenData){
              switch (this.printByTypes[this.printType].name){
                case 'Program':
                  if (!this.filteredData.some(e => e.id === order.program.id)){
                    let temp = {
                      id: order.program.id,
                      name: order.program.name,
                    }
                    this.filteredData.push(temp)
                  }
                  break
                case 'Rank':
                  if (!this.filteredData.some(e => e.id === order.rank.id)){
                    let temp = {
                      id: order.rank.id,
                      name: order.rank.name,
                    }
                    this.filteredData.push(temp)
                  }
                  break
                case 'Tests':
                case 'Groups':
                  if (!this.filteredData.some(e => e.id === order.testing_type_id)){
                    let typeName = ''
                    for (let type of this.testing_types){
                      if (order.testing_type_id === type.id){
                        typeName = type.name
                        break
                      }
                    }
                    let temp = {
                      id: order.testing_type_id,
                      name: typeName,
                    }
                    this.filteredData.push(temp)
                  }
                  break
                case 'Rank Level':
                  if (!this.filteredData.some(e => e.id === order.rank.rank_type_id)){
                    let levelName = ''
                    for (let level of this.rank_types){
                      if (order.rank.rank_type_id === level.id){
                        levelName = level.name
                        break
                      }
                    }
                    let temp = {
                      id: order.rank.rank_type_id,
                      name: levelName,
                    }
                    this.filteredData.push(temp)
                  }
                  break
              }
            }
            break
          case 'rank-curriculum':
            this.filteredData = this.givenData.filter(data => !this.rankTypeFilter || data.rank_type_id === this.rankTypeFilter)
            return
        }
        if (this.selectAll){
          this.clickSelect()
          this.clickSelect()
        } else {
          this.clickSelect()
        }
      }
    },
    clickSelect() {
      this.selectAll = !this.selectAll
      if (this.selectAll === false) {
        this.selectedData = [];
      } else {
        for (const data of this.filteredData) {
          if (data) {
            this.selectedData.push(data.id);
          }
        }
      }
    },
    checkSelected(id){
      return this.selectedData.includes(id)
    },
    isCheckedProgram(id){
      return this.addedPrograms.includes(id)
    },
    selectID(id){
      if (this.selectedData.includes((id))) {
        let index = this.selectedData.indexOf(id)
        this.selectedData.splice(index, 1)
      } else {
        this.selectedData.push(id)
      }
    },
    selectFromCurrentProgram(array, id){
      if (this.addedPrograms.length === 0){
        selectArray(this)
      } else {
        if (this.addedPrograms.includes(id)){
          removeArray(this)
        } else {
          selectArray(this)
        }
      }

      function selectArray(VO){
        VO.addedPrograms.push(id)
        for (const data of array) {
          if (data) {
            VO.selectedData.push(data.id);
          }
        }
      }
      function removeArray(VO){
        let index = VO.addedPrograms.indexOf(id)
        VO.addedPrograms.splice(index, 1)
        for (const data of array){
          index = VO.selectedData.indexOf(data.id)
          VO.selectedData.splice(index, 1)
        }
      }
    },


    //          Print Functions
    preCurPrint(val){
      this.printCurrent = val
      this.print()
    },
    preRankCurPrint(){
      this.openModal('rankCur')
    },
    async print(){
      this.loading = true
      this.precentage = 0
      /*console.log(this.typeString, this.type)*/
      switch (this.type){
        case 'event-test-sheets':
          await this.printTestingSheets()
          break
        case 'foundation-certs':
          await this.printFoundationCerts('foundation')
          break
        case 'color-belt-certs':
          await this.printFoundationCerts('red')
          break
        case 'prelim-certs':
          await this.printFoundationCerts('prelim')
          break
        case 'black-belt-certs':
          await this.printFoundationCerts('black')
          break
        case 'single-score':
        case 'event-scores':
          await this.getModifiers()
          await this.getPenalties()
          await this.getBoards()
          await this.getTechniques()
          await this.getSupportOptions()
          await this.getAllDirections()
          await this.printScores()
          break
        case 'single-outline':
        case 'event-outlines':
          await this.getModifiers()
          await this.getPenalties()
          await this.getBoards()
          await this.getTechniques()
          await this.getSupportOptions()
          await this.getAllDirections()
          await this.printOutlines()
          break
        case 'event-roll-sheet':
          await this.printRollSheets()
          break
        case 'event-call-sheet':
          await this.printCallSheets()
          break
        case 'curriculum':
          await this.printCurriculum()
          break
        case 'event-curriculum':
          await this.printCurriculum()
          break
        case 'bbt-test-sheets':
          await this.printBBTestingSheets()
          break
        case 'bbt-roll-sheet':
          await this.printBBTRollSheets()
          break
        case 'rank-curriculum':
          if (!this.printByTapes){
            await this.printCurriculum()
          } else {
            await this.printTapes()
          }
          break
        case 'event-board-count':
          this.printBoardCount()
          break
        default:
          this.error_txt = "Doesn't work yet"
          this.loading = false
          this.openModal('error')
          return
      }
      this.loading = false
    },
    printTestingSheets,
    printBBTestingSheets,
    printCurriculum,
    printTapes,
    downloadPDF,
    printFoundationCerts,
    printScores,
    printOutlines,
    printRollSheets,
    printBBTRollSheets,
    printCallSheets,
    printBoardCount,
    setTechniqueNames,
    calculateScoresForOutline,
  }
}
</script>

<style scoped>

</style>
