import JsPDF from 'jspdf';
import moment from 'moment';
import PoomsaeAPIService from "../../../../servicehandlers/PoomsaeAPIService";
import KicksAPIService from "../../../../servicehandlers/KicksAPIService";
import SelfDefenseTechniquesAPIService from "../../../../servicehandlers/SelfDefenseTechniquesAPIService";
import KickCombosAPIService from "../../../../servicehandlers/KickCombosAPIService";
import TechniquesAPIService from "../../../../servicehandlers/TechniquesAPIService";
import BreakingTechniquesAPIService from "../../../../servicehandlers/BreakingTechniquesAPIService";

const poomsaeAPIService = new PoomsaeAPIService()
const kicksAPIService = new KicksAPIService()
const selfDefenseTechniquesAPIService = new SelfDefenseTechniquesAPIService()
const kickCombosAPIService = new KickCombosAPIService()
const techniqueAPIService = new TechniquesAPIService()
const breakingTechniquesAPIService = new BreakingTechniquesAPIService();
export async function printTapes() {

  // get kicks and motions for the kick combos
  let tapes = ['Kicking', 'Kick Combos', 'Poomsae', 'Techniques', 'Self Defense', 'Breaking']
  let all_kicks = null;
  try {
    all_kicks = await kicksAPIService.getKick(this.$router)
  } catch (e) {
    this.error_txt = 'Error getting kicks: ' + e;
    this.openModal('error');
    return;
  }
  let all_kick_combos = null;
  try {
    all_kick_combos = await kickCombosAPIService.getKickCombos(this.$router)
  } catch (e) {
    this.error_txt = 'Error getting kick combos: ' + e;
    this.openModal('error');
    return;
  }
  let all_poomsae = null;
  try {
    all_poomsae = await poomsaeAPIService.getPoomsae(this.$router)
  } catch (e) {
    this.error_txt = 'Error getting poomsae: ' + e;
    this.openModal('error');
    return;
  }
  let all_techs = null;
  try {
    all_techs = await techniqueAPIService.getTechnique(this.$router)
  } catch (e) {
    this.error_txt = 'Error getting techniques: ' + e;
    this.openModal('error');
    return;
  }
  let all_self_defs = null;
  try {
    all_self_defs = await selfDefenseTechniquesAPIService.getSelfDefenseTechniques(this.$router)
  } catch (e) {
    this.error_txt = 'Error getting self defense: ' + e;
    this.openModal('error');
    return;
  }

  const date = moment().format('MM-DD-YYYY');
  const doc = new JsPDF({
    orientation: "portrait",
    format: 'letter',
  });
  doc.setFontSize('12');
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const halfPage = pageWidth / 2;

  let fileName = 'Tapes Curriculum.pdf'

  for (let tape of tapes){
    let index = tapes.indexOf(tape) + 1
    let minPrecent = (index / tapes.length) * 100
    let gap = 100.00 / tapes.length

    let x = 10;
    let y = 5;

    doc.setFontSize('12');
    doc.text(date, x, y);
    y += 7;
    doc.setFontSize('12');
    doc.text('Beyond Sports Taekwondo Curriculum', x, y);
    y += 7;

    doc.setFontSize('12');
    doc.text(tape, x, y);
    if (tape === 'Techniques'){
      if (this.stepSparring.pdf){
        doc.setFontSize('10')
        doc.text('Step Sparring File: ', halfPage, y - 5)
        doc.setFontSize('8')
        doc.text(this.stepSparring.pdf, halfPage + 3, y)
      } else {
        this.error_txt = "Current step sparring doesn't have an assigned file"
        this.openModal('error')
      }
    }
    doc.setFontSize('8');
    y += 7;

    for (let id of this.selectedData){
      for (let rank of this.givenData){
        if (id === rank.id){
          switch (tape){
            case 'Kicking':
              if (y > (pageHeight - 20)){
                doc.addPage()
                y = 5
              }
              doc.setFontSize('12');
              doc.text(rank.name, x, y);
              doc.setFontSize('8');
              y += 3;
              doc.line(x, y, pageWidth - 10, y);
              y += 5;
              let rank_kicks = null;
              try {
                rank_kicks = await kicksAPIService.getKickByRank(rank.id, this.$router)
                for (let item of rank_kicks){
                  for (let kick of all_kicks){
                    if (kick.id === item.kick_id){
                      if (y > (pageHeight - 10)){
                        doc.addPage()
                      }
                      if (kick.description) {
                        doc.text(kick.description, x + 5, y);
                      }
                      y += 5;
                      break
                    }
                  }
                }
                y += 3;
              } catch (e) {
                this.error_txt = 'Error getting kicks by userRank: ' + e;
                this.openModal('error');
                return;
              }
              break
            case 'Kick Combos':
              if (y > (pageHeight - 20)){
                doc.addPage()
                y = 5
              }
              doc.setFontSize('12');
              doc.text(rank.name, x, y);
              doc.setFontSize('8');
              y += 3;
              doc.line(x, y, pageWidth - 10, y);
              y += 5;
              let rank_kick_combos = null;
              try {
                rank_kick_combos = await kickCombosAPIService.getKickCombosByRank(rank.id, this.$router)
                for (let item of rank_kick_combos){
                  for (let kick_combo of all_kick_combos){
                    if (kick_combo.id === item.kick_combo_id){
                      if (y > (pageHeight - 10)){
                        doc.addPage()
                      }
                      if (kick_combo.description) {
                        doc.text(kick_combo.description, x + 5, y);
                      }
                      y += 5;
                      break
                    }
                  }
                }
                y += 3;
              } catch (e) {
                this.error_txt = 'Error getting kick combos: ' + e;
                this.openModal('error');
                return;
              }

              break
            case 'Poomsae':
              if (y > (pageHeight - 20)){
                doc.addPage()
                y = 5
              }
              doc.setFontSize('12');
              doc.text(rank.name, x, y);
              doc.setFontSize('8');
              y += 3;
              doc.line(x, y, pageWidth - 10, y);
              y += 5;

              let poomsae_by_rank = null;
              try {
                poomsae_by_rank = await poomsaeAPIService.getPoomsaeByRank(rank.id, this.$router);
                for (let item of poomsae_by_rank){
                  for (let poomsae of all_poomsae){
                    if (poomsae.id === item.poomsae_id){
                      if (y > (pageHeight - 10)){
                        doc.addPage()
                      }
                      if (poomsae_by_rank.indexOf(item) === 0) {
                        if (poomsae.description) {
                          doc.text('Current: ' + poomsae.description, x + 5, y);
                        }
                        y += 5;
                      } else {
                        if (poomsae.description) {
                          doc.text('Previous: ' + poomsae.description, x + 5, y);
                        }
                        y += 5;
                      }
                      break
                    }
                  }
                }
                y += 3;
              } catch (e) {
                this.error_txt = 'Unresolved data error getting poomsae to ranks: ';
                this.openModal('error');
              }
              break
            case 'Techniques':
              if (y > (pageHeight - 20)){
                doc.addPage()
                y = 5
              }
              doc.setFontSize('12');
              doc.text(rank.name, x, y);
              doc.setFontSize('8');
              y += 3;
              doc.line(x, y, pageWidth - 10, y);
              y += 5;
              let techniques_by_rank = null;
              try {
                techniques_by_rank = await techniqueAPIService.getTechniqueByRank(rank.id, this.$router);
                for (let item of techniques_by_rank){
                  for (let tech of all_techs){
                    if (tech.id === item.technique_id){
                      if (y > (pageHeight - 10)){
                        doc.addPage()
                      }
                      if (tech.description) {
                        doc.text(tech.description, x + 5, y);
                      }
                      y += 5;
                      break
                    }
                  }
                }
                y += 3;
              } catch (e) {
                this.error_txt = 'Unresolved data error getting techniques to ranks: ';
                this.openModal('error');
              }
              break
            case 'Self Defense':
              if (y > (pageHeight - 20)){
                doc.addPage()
                y = 5
              }
              doc.setFontSize('12');
              doc.text(rank.name, x, y);
              doc.setFontSize('8');
              y += 3;
              doc.line(x, y, pageWidth - 10, y);
              y += 5;
              let self_defense_by_rank = null;
              try {
                self_defense_by_rank = await selfDefenseTechniquesAPIService.getSelfDefenseTechniquesByRank(rank.id, this.$router);
                for (let item of self_defense_by_rank){
                  for (let self_def of all_self_defs){
                    if (y > (pageHeight - 10)){
                      doc.addPage()
                    }
                    if (self_def.id === item.self_defense_techniques_id){
                      if (self_def.description) {
                        doc.text(self_def.description, x + 5, y);
                      }
                      y += 5;
                      break
                    }
                  }
                }
                y += 3;
              } catch (e) {
                this.error_txt = 'Unresolved data error getting self defense techniques to ranks: ';
                this.openModal('error');
              }
              break
            case 'Breaking':
              if (y > (pageHeight - 20)){
                doc.addPage()
                y = 5
              }
              doc.setFontSize('12');
              doc.text(rank.name, x, y);
              doc.setFontSize('8');
              y += 3;
              doc.line(x, y, pageWidth - 10, y);
              y += 5;
              let breaking_by_rank = null;
              try {
                breaking_by_rank = await breakingTechniquesAPIService.getBreakingTechniquesByRankID(rank.id, this.$router);
                const techniques = breaking_by_rank.breaking_techniques;
                for (const technique of techniques) {
                  if (y > (pageHeight - 10)){
                    doc.addPage()
                  }
                  if (kick.description) {
                    doc.text(technique.description, x + 5, y);
                  }
                  y += 5;
                }
              } catch (e) {
                this.error_txt = 'Unresolved data error getting self techniques to ranks: ';
                this.openModal('error');
              }
              break
          }
        }
      }
      index = this.selectedData.indexOf(id) + 1
      let currentPercent = (index / this.selectedData.length)
      currentPercent = minPrecent + (currentPercent * gap)
      this.precentage = parseFloat((Math.round(currentPercent * 100) / 100).toFixed(1))
      if (this.precentage > 100){
        this.precentage = 100
      }
    }

    if (tapes.indexOf(tape) !== tapes.length - 1){
      doc.addPage()
    }
  }
  doc.save(fileName);
}
