import CommonAPIService from './CommonHandler';

export class OutlinesAPIService {
  constructor() {
    this.search = '';
    this.CommonAPIService = new CommonAPIService();
  }

  submitScore(outline_id, judge_id, judge_num, org_score, modifier_score,
              height_score, transition_score, epic_score, total_score, router) {
    const url = `/api/outlines/${outline_id}/submitscore`;
    return this.CommonAPIService.postCall(url, {judge_id, judge_num, org_score,
      modifier_score, height_score, transition_score, epic_score, total_score }, router);
  }

  getOutlinesSimple(userId, router) {
    const url = `/api/outlines/${userId}/simple`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  getRegisteredOutlinesByEvent(event_id, router) {
    const url = `/api/outlines/${event_id}/registered`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  getOutlineByID(outlineId, router) {
    const url = `/api/outlines/${outlineId}`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  getOutlines(router) {
    const url = '/api/outlines';
    return this.CommonAPIService.getCall(url, '', router);
  }

  getOutline(outlineID, router) {
    const url = `/api/outlines/${outlineID}`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  getOutlineByUser(userId, router) {
    const url = `/api/outlines/${userId}/user`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  getJudgeOutline(userId, router) {
    const url = `/api/outlines/${userId}/judgeoutline`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  addOutline(outline, router) {
    const url = '/api/outlines';
    return this.CommonAPIService.postCall(url, outline, router);
  }

  updateOutline(outlineId, outline, router) {
    const url = `/api/outlines/${outlineId}`;
    return this.CommonAPIService.putCall(url, outline, router);
  }

  lockOutline(outlineId, router){
    const url = `/api/outlines/${outlineId}/lock`;
    return this.CommonAPIService.putCall(url, '', router);
  }

  unlockOutline(outlineId, router){
    const url = `/api/outlines/${outlineId}/unlock`;
    return this.CommonAPIService.putCall(url, '', router);
  }

  updateSingleOutline(outlineId, outline, router) {
    const url = `/api/outlines/${outlineId}/single`;
    return this.CommonAPIService.putCall(url, outline, router);
  }

  deleteOutline(outlineId, router) {
    const url = `/api/outlines/${outlineId}`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }

  getBase64File(filename, router) {
    const url = `/api/outlines/${filename}/file`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  assignOutline(outlineId, router){
    const url = `/api/outlines/${outlineId}/assign`
    return this.CommonAPIService.putCall(url, '', router)
  }

  getOutlineJudgeScores(outline_id, router) {
    const url = `/api/outlines/${outline_id}/judgescores`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  copyOutline(outline_id, router) {
    const url = `/api/outlines/${outline_id}/copy`;
    return this.CommonAPIService.postCall(url, '', router);
  }

  getDefaultOutlineDetailsByRankId(rank_id, router){
    const url = `/api/outlines/${rank_id}/default`
    return this.CommonAPIService.postCall(url, '', router)
  }
}

export default OutlinesAPIService;
