import JsPDF from 'jspdf';
import OutlineAPIService from "../../../../servicehandlers/OutlinesAPIService";
const outlineAPIService = new OutlineAPIService();
export async function printBoardCount() {
  let missingOutlines = false
  let outlines = []
  for (let data of this.givenData){
    if (data.outline){
      await outlineAPIService.getOutline(data.outline.id, this.$router)
        .then((outline) => {
          let temp = {
            outline: outline,
            rank: data.rank,
          }
          outlines.push(temp);
        })
        .catch((error) => {
          this.error_txt = 'Lock failed: ' + error;
          this.openModal('error');
        });
    } else {
      this.error_txt = 'Be aware that one or more of the competitors is missing an outline'
      this.openModal('error')
      this.loading = false
      missingOutlines = true
    }
  }
  return Promise.all(outlines)
    .then(() => {
      const doc = new JsPDF({
        orientation: "portrait",
        format: 'credit-card',
      });
      let x = doc.internal.pageSize.getWidth() / 2
      let y = 10

      let boards = {}
      for (let board of this.dropdown_boards.filter(data => data.id !== null)){
        boards[board.id] = {
          name: board.name,
          count: 0
        }
      }

      for (const data of outlines) {
        for (let breakObj of data.outline.breaking_techniques_to_outlines){
          boards[breakObj.board_id].count += 1
        }
      }

      doc.text('Board Count:', x, y, 'center')
      y += 8

      for (let key of Object.keys(boards)){
        doc.text(boards[key].name + ': ' + boards[key].count, x, y, 'center')
        y += 6
      }
      if (missingOutlines){
        doc.setTextColor(255, 0, 0);
        y += 2
        doc.text('Missing', x, y, 'center')
        y += 6
        doc.text('one or more', x, y, 'center')
        y += 6
        doc.text('Outlines', x, y, 'center')
      }


      let fileName = this.printName + ' Board Count.pdf'
      doc.save(fileName);
    })


}
